import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import React ,{useState,useEffect} from 'react';
import { AuthPage } from '../../../../components';
import './styles.scss';
import { getTeachernotes } from 'api/api';

// import { SideMenu } from "App/ITCTeacher/Components";

// const language: string[] = ['MATHS (B.MELAYU) STD 5', 'MATHS (B.MELAYU) STD 6'];

export const Notes = () => {

  const [language ,setlanguage] = useState([])
  
  useEffect(()=>{
    getTeachernotes().then(res => {
      setlanguage(res.data.data);
    })
  },[])
  console.log(language,"my teacher language")


  // const handlelink = () =>{
  //      window.location.href = ""
  // }


  return (
    <>

    {/* <SideMenu/> */}

    <AuthPage title='Notes' showBackButton>
      <div className='ion-padding'>
        <p className='heading'>Please select your preferred subject</p>
        <Stack sx={{ alignItems: 'center' }} spacing={2}>
          {language.map((item,index) => (
            <IonButton
              key={index + 'note'}
              style={{ minWidth: 'calc(100% - 40px)', height: '47px' }}
              shape='round'
              color='primary'
              expand='block'
              // onClick={() => handlelink()}
            >
              {/* {language} */}

              {item.subject.id} {item.subject.name} {item.subject.standard_name}
            
            </IonButton>
          ))}
        </Stack>
      </div>
    </AuthPage>
    </>
  );
};