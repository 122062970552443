import React, { useState } from "react";
import "./index.scss";
import { Tabs, Typography } from "@mui/material";
import { AuthPage } from "../../../../components";
import { IonBadge } from "@ionic/react";
import { useHistory } from "react-router";
import { style } from "./../../../../Utility";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import { SideMenu } from "App/ITCTeacher/Components";

export const Invoice = () => {
    const history = useHistory();
    const [value, setValue] = React.useState("one");

    let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
    let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
        {/* <SideMenu/> */}

        <AuthPage title="Profile">
            <div className='contactus'>
                <Typography className='heading'>
                    JASVINRAJ SIVAPALAN
                </Typography>
                <p className="subHeading" style={{ color: "black" }}>Teacher</p>

            </div>
            <table className="invoice-table">

                <tbody>
                    <tr>
                        <td className="first-col">Date Issued</td>
                        <td>: 4/2/2022</td>
                    </tr>
                    <tr>
                        <td className="first-col">Payment Method</td>
                        <td>: Online Banking</td>
                    </tr>
                    <tr>
                        <td className="first-col">Reference</td>
                        <td>: #February2022</td>
                    </tr>
                </tbody>

            </table>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="simpleTable">
                    <table className="" style={{width:"100%"}}>
                        <thead>
                            <tr >
                                <th>No</th>
                                <th style={{width:"150px"}}>Class Attended</th>
                                <th style={{width:"150px"}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Maths (B.Melayu)</td>
                                <td>Standard 6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </AuthPage>
        </>
    )
};
