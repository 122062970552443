import React, { useState } from "react";
import "./index.scss";
import { Tabs, Typography } from "@mui/material";
import { AuthPage } from "../../../../components";
import { IonBadge } from "@ionic/react";
import { useHistory } from "react-router";
import { style } from "./../../../../Utility";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation } from "react-router-dom";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from "App/ITCStudent/Components";

export const PaymentHistory = () => {

  const history = useHistory();
  const [value, setValue] = React.useState("one");
  const location = useLocation();
  const profile = JSON.parse(localStorage.getItem("profile"))
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <SideMenu />
      <AuthPage title="PAYMENT" showBackButton>
        <p className="color-tertiary heading" style={{ marginBottom: "10px" }}>{profile.name}</p>
        <p className="subHeading">{profile.student_details.standard_id}</p>
        <div className=" payment-page">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="fullWidth"
                  aria-label="secondary tabs example"
                >
                  <Tab value="one" label="To Pay" />
                  <Tab value="two" label="Payment History" />
                </Tabs>
              </Box>
              <TabPanel value="one">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div style={{ width: style.maxWidth }}>
                    <div className="simpleTable">
                      <table>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th style={{ width: "100px" }}>Amount</th>
                            <th>Due Date</th>
                            <th>Pay</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>RM 130</td>
                            <td>5/4/2022</td>
                            <td>
                              <IonBadge
                                className="payBadge"
                                color="primary"
                                onClick={() => history.push("/s/payment")}
                              >
                                Pay
                              </IonBadge>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>RM 130</td>
                            <td>5/5/2022</td>
                            <td>
                              <IonBadge
                                className="payBadge"
                                color="primary"
                                onClick={() => history.push("/s/payment")}
                              >
                                Pay
                              </IonBadge>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>RM 130</td>
                            <td>5/6/2022</td>
                            <td>
                              <IonBadge
                                className="payBadge"
                                color="primary"
                                onClick={() => history.push("/s/payment")}
                              >
                                Pay
                              </IonBadge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="two">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div style={{ width: style.maxWidth }}>
                    <div className="simpleTable">
                      <table>
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th style={{ width: "200px" }}>Receipt</th>
                            <th>Date</th>
                            <th style={{ width: "100px" }}>Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>#8034569</td>
                            <td>4/2/2022</td>
                            <td>
                              <IonBadge
                                className="payBadge"
                                color="secondary"
                                onClick={() => history.push("/s/invoice")}
                              >
                                View
                              </IonBadge>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>#8034670</td>
                            <td>4/3/2022</td>
                            <td>
                              <IonBadge
                                className="payBadge"
                                color="secondary"
                                onClick={() => history.push("/s/invoice")}
                              >
                                View
                              </IonBadge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </AuthPage>
    </>
  );
};
