import React, {useState} from "react";
import "./index.scss";
import { Tabs, Typography } from "@mui/material";
import { AuthPage } from "../../../../components";
import { IonBadge } from "@ionic/react";
import { useHistory } from "react-router";
import { style } from "./../../../../Utility";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import { SideMenu } from "App/ITCTeacher/Components";

export const Salary = () => {
  
  const history = useHistory();
  
  const [value, setValue] = React.useState("one");
  
  let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  
  let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title="Salary" showBackButton>
      <p className="color-tertiary heading">{profileData.name}</p>
      <p className="subHeading">{profileData.role}</p>
      <div className=" payment-page">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                aria-label="secondary tabs example"
              >
                <Tab value="one" label="Earning" />
                <Tab value="two" label="Earning History" />
              </Tabs>
            </Box>
            
            <TabPanel value="one">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <div style={{ width: style.maxWidth }}>
                  <div className="simpleTable">
                    <table>
                      <thead>
                        <tr>
                          <th style={{width:"150px"}}>No.</th>
                          <th style={{width:"150px"}}>Reference</th>
                          <th style={{width:"150px"}}>Amount</th>
                          <th style={{width:"150px"}}>Date Issued</th>
                          <th style={{width:"150px"}}>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>#March2022</td>
                          <td>RM400</td>
                          <td>15/3/2022</td>
                          <td>
                            <IonBadge
                              className="payBadge"
                              color="success"
                            >
                              View
                            </IonBadge>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="two">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <div style={{ width: style.maxWidth }}>
                  <div className="simpleTable">
                    <table>
                      <thead>
                        <tr>
                          <th style={{width:"150px"}}>No.</th>
                          <th style={{width:"150px"}}>Reference</th>
                          <th style={{width:"150px"}}>Amount</th>
                          <th style={{width:"150px"}}>Date Issued</th>
                          <th style={{width:"150px"}}>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>#March2022</td>
                          <td>RM400</td>
                          <td>15/3/2022</td>
                          <td>
                            <IonBadge
                              className="payBadge"
                              color="secondary"
                              onClick={()=>history.push('/t/invoice')}
                            >
                              VIEW
                            </IonBadge>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>#February2022</td>
                          <td>RM400</td>
                          <td>15/2/2022</td>
                          <td>
                            <IonBadge
                              className="payBadge"
                              color="secondary"
                            >
                              VIEW
                            </IonBadge>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </AuthPage>
    </>
  );
};
 