import "./index.scss";
import React, { useEffect, useState } from "react";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { Stack, Typography } from "@mui/material";
import { pencil } from "ionicons/icons";
import { style } from "./../../../../Utility";
import { useHistory } from "react-router";
import { getReferrel } from '../../../../api/api';
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';


interface ProfileProps {}

export const Profile = (props: ProfileProps) => {

  // let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  // let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  
  const [profileData, setProfileData] :any = useState({});

  useEffect(() => {
    GET("student/profile").then((response: any) => {
      const {data, status} = response
      if (status) {
        setProfileData(data.data.data);
      }
      setLoader(false)
    });
  }, []);
  console.log(profileData,"datedatedate")
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [referrelList, setReferrelList] = useState([])
  useEffect(()=>{
    getReferrel().then(res => {
      const {data, status} = res;
      if(status) {
        setReferrelList(data.data)
      }
      setLoader(false)
    })
  }, [])
  console.log(referrelList,"packageeeeeeeee");
  
  if(loader)
    return <CircularProgress />
  return (
    <>
    <SideMenu/>
    <AuthPage title="Profile" containerWidth="md">
      <div className="ion-padding">
      <StudentDashboardCard
          name={profileData.name}
          title={profileData.class? profileData.class : ""}
          image={profileData?.image}
          showCamera
          onView={() => history.push('/s/status')}
        />
        <Typography className="tableHeader" sx={{ paddingTop: "2rem" }}>
          Students Referred
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="simpleTable">
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th style={{width:"150px"}}>Student Name</th>
                  <th style={{width:"150px"}}>Standard / Form</th>
                  <th style={{width:"150px"}}>Discount</th>
                  <th style={{width:"150px"}}>Date</th>
                </tr>
              </thead>
              <tbody>
                {referrelList.map((refer, index)=>(
                  <tr>
                    <td>{index+1}</td>
                    <td>{refer.name}</td>
                    <td>{refer.standard_name}</td>
                    <td>{refer.discount}</td>
                    <td>{refer.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
    
  );
};
