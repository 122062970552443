import "./index.scss";
import React from "react";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { Stack, Typography } from "@mui/material";
import { pencil } from "ionicons/icons";
import { style } from "./../../../../Utility";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

interface InvoiceProps {}

export const Invoice = (props: InvoiceProps) => {
  return (
    <>
      <SideMenu/>
    
    <AuthPage title="Invoice" showBackButton containerWidth="md">
      <div className="ion-padding">
        <h5 className="studentName">
          <p className="color-tertiary m0">Hashini Shivaguru</p>
          <div className="class">FORM 3</div>
        </h5>

        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <table width="70%">
            <tbody>
              <tr>
                <td>Date</td>
                <td>:</td>
                <td>4/2/2022</td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td>:</td>
                <td>Online Banking</td>
              </tr>
              <tr>
                <td>Receipt Reference</td>
                <td>:</td>
                <td>#802355</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="simpleTable">
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Form 3 full package</td>
                  <td>RM 130</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <b>TOTAL</b>
                  </td>
                  <td>
                    <b>RM 130</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
