export const Config = {
  isLoggedIn: false,
  isTeacher: false,
  isStudent: false,
};

export const style: Record<string, any> = {};

export const setStyle = (styles: Record<string, any>): void => {
  Object.keys(styles).forEach((key) => (style[key] = styles[key]));
};

export const setType = (type: 'teacher' | 'student') => {
  if (type === 'teacher') {
    Config.isTeacher = true;
    Config.isStudent = false;
  }
  if (type === 'student') {
    Config.isTeacher = false;
    Config.isStudent = true;
  }
};

export const setLoggedIn = (loggedIn: boolean) => {
  Config.isLoggedIn = loggedIn;
};


export const protectedRoute = (is_protected = true) => {
  // let rt = false
  if (!localStorage.getItem("itc_token")) {
    if(is_protected) {
      window.location.href = "/";
    } else {
      return true
    }
  } else {
    if(!is_protected) {
      window.location.href = "/tabs/profile";
    } else {
      return true
    }
    return is_protected
  }
}