import './index.scss';
import { IonCheckbox } from '@ionic/react';
import { Box, Typography } from '@mui/material'
import { Callback } from 'yup/lib/types';
export interface LanguageOptions {
    name: string;
    day: string;
    time: string;
    checked: boolean;
};

export interface LanguageBoxProps {
    title?: string;
    languages: LanguageOptions[];
    selected?: boolean;
    indexdata?: number;
    handleCheck?: CallableFunction;
};


export const LanguageBox = (props: LanguageBoxProps) => {

    return (
        <Box className={"language-box"}>
            {props.title && <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>{props.title}</Typography>}
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {props.languages.map((language, index) =>
                    <div key={index + "language"}>
                        <div style={{ paddingTop: '0.5rem' }}>

                            <IonCheckbox color="success" className="ion-checkbox-lang" style={{ verticalAlign: 'middle' }}
                                value={language.name} onClick={() => props.handleCheck(props.indexdata, index)} />
                            <span style={{ fontWeight: 'bold', fontSize: '14px' }} >{language.name} </span>
                        </div>
                    </div>
                )}
            </div>
            <div>
            </div>
        </Box>
    )
}
