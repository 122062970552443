import { IonButton } from '@ionic/react';
import { Box, Typography } from '@mui/material';
import React from 'react';
import './classdetail.scss';
import { useHistory } from "react-router";


export interface ClassDetailsProps {
  subject: string;
  timing: string;
  classLink: string;
}

export const ClassDetails = (props: ClassDetailsProps) => {
  
  const history = useHistory();

  const handelClick = () => {
    window.location.href = props.classLink;
  }
  
  return (
    <Box className='classDetailCard'>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {props.subject}
      </Typography>
      <Typography
        sx={{ textAlign: 'center', color: 'black', fontSize: 'small' }}
      >
        Time: {props.timing}
      </Typography>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className='ctaBtnWrapper'
      >
        <a href={props.classLink}>
          <IonButton onClick={()=>handelClick()} shape='round' color='secondary' size='small'>
          LINK
        </IonButton>
          </a>
        <IonButton shape='round' color='primary' size='small'>
          NOTES
        </IonButton>
      </div>
    </Box>
  );
};
