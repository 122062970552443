import {
  Announcement,
  Attendance,
  ComboPackage,
  ContactUs,
  EnrollSubjects,
  EnrollSubjects1,
  Feedback,
  IndividualSubjects,
  InviteFriend,
  JoinClasses,
  Notes,
  Notification,
  Payment,
  Profile,
  Recording,
  StudentProfile,
  StudentProgress,
  TimeTable,
  UpcomingPrograms,
  Invoice,
  PaymentHistory,
  NotesList,
  Success
} from "./Pages";

export interface AppRoutingOptions {
  path: string;
  exact: boolean;
  Component: React.FunctionComponent;
}

export const StudentAppRoutes: AppRoutingOptions[] = [
  {
    path: "/s/profile",
    exact: true,
    Component: Profile,
  },
  {
    path: "/s/payment-history",
    exact: true,
    Component: PaymentHistory,
  },
  {
    path: "/s/notifications",
    exact: true,
    Component: Notification,
  },
  {
    path: "/s/status",
    exact: true,
    Component: StudentProfile,
  },
  {
    path: "/s/enroll-subject",
    exact: true,
    Component: EnrollSubjects,
  },
  {
    path: "/s/combo-package/:id",
    exact: true,
    Component: ComboPackage,
  },
  {
    path: "/s/individual-subjects",
    exact: true,
    Component: IndividualSubjects,
  },
  {
    path: "/s/enroll-subject1/:id",
    exact: true,
    Component: EnrollSubjects1,
  },
  {
    path: "/s/payment",
    exact: true,
    Component: Payment,
  },
  {
    path: "/s/invoice",
    exact: true,
    Component: Invoice,
  },
  {
    path: "/s/join-class",
    exact: true,
    Component: JoinClasses,
  },
  {
    path: "/s/recording",
    exact: true,
    Component: Recording,
  },
  {
    path: "/s/attendance",
    exact: true,
    Component: Attendance,
  },
  {
    path: "/s/notes",
    exact: true,
    Component: Notes,
  },
  {
    path: "/s/time-table",
    exact: true,
    Component: TimeTable,
  },
  {
    path: "/s/student-progress",
    exact: true,
    Component: StudentProgress,
  },
  {
    path: "/s/feedback",
    exact: true,
    Component: Feedback,
  },
  {
    path: "/s/announcement",
    exact: true,
    Component: Announcement,
  },
  {
    path: "/s/upcoming-programs",
    exact: true,
    Component: UpcomingPrograms,
  },
  {
    path: "/s/contact-us",
    exact: true,
    Component: ContactUs,
  },
  {
    path: "/s/invite-friend",
    exact: true,
    Component: InviteFriend,
  },
  {
    path: "/s/notes/list/:subjectId",
    exact: true,
    Component: NotesList,
  },
  {
    path: "/payment/success",
    exact: true,
    Component: Success,
  },
];
