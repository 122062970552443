import { IonButton, IonToolbar } from '@ionic/react';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useState } from 'react';
import { TextInput } from '../../App/ITCTeacher/Components/inputFields';
import { FormattedMessage } from 'react-intl';
import { useFormik } from "formik";
import * as yup from "yup";
export const OtherDetails = (props: any) => {
  
  const [personName, setPersonName] = useState<string[]>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '100%',
      },
    },
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 11px)',
      right: 'calc(50% + 11px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#AB1F23',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#AB1F23',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#AB1F23',
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className='completed-circle' />
        ) : (
          <div className='circle' />
        )}
      </QontoStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));
  const steps = ["Teacher's Information", 'Other Information'];
  const otherSchema = yup.object({
    occupation: yup.string().required(),
    company_name: yup.string().required(),
    designation: yup.string().required(),
    education_id: yup.string().required(),
    institute: yup.string().required(),
    preffered_subject: yup.string().required(),
    
  })
  const formik = useFormik({
    initialValues: {
      
      occupation: "",
      company_name: "",
      education_id: "",
      designation: "",
      institute: "",
      preffered_subject: "",
    },
    validationSchema: otherSchema,
    onSubmit: (values:any) => {
      console.log("values :>> ", values);
      props.handleNext(values)
    },
  });
  console.log('formik.errors', formik.errors)
  return (
    <Container maxWidth='md'>
      <IonToolbar className='stickyStepper'>
        <Stepper alternativeLabel activeStep={2} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </IonToolbar>
      <h3 className='sectionHeading'>Other details</h3>
      <form className='infoForm' onSubmit = {formik.handleSubmit}>
      <TextInput formik={formik} label={"Occupation"} field_name={"occupation"} />
      <TextInput formik={formik} label={"Company Name"} field_name={"company_name"} />
    

        {/* <TextField
          label='Occupation'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('occupation', e.target.value)}

        /> */}

        {/* <TextField
          label='Company Name'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('company_name', e.target.value)}

        /> */}

        <FormControl>
          <InputLabel id='demo-multiple-name-label'>
            Highest Education
          </InputLabel>
          <Select
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            value={formik.values.education_id}
            onChange={formik.handleChange}
            input={<OutlinedInput label='Name' />}
            MenuProps={MenuProps}
            name="education_id"
          >
            {names.map((name, i) => (
              <MenuItem key={name} value={i}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <span style={{ color: "red" }}>{formik.errors.education_id}</span>
        </FormControl>
        <TextInput formik={formik} label={"Designation"} field_name={"designation"} />
      <TextInput formik={formik} label={"Institution"} field_name={"institute"} />
      <TextInput formik={formik} label={"Preffered Subject"} field_name={"preffered_subject"} />
        {/* <TextField
          label='Designation'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('designation', e.target.value)}
        /> */}

        {/* 
          <TextField
          label='Institution'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('institute', e.target.value)}
        /> */}

        {/* <TextField
          label='Preffered Subject'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('preffered_subject', e.target.value)}

        /> */}

<div className='btnwrap'>
          <IonButton
            color='secondary'
            expand='block'
            shape='round'
            size='large'
            type="submit"
            // onClick={() => setButtonFn()}
          >
            <FormattedMessage id='signup.next_button' />
          </IonButton>
          {/* {steps > 0 && ( */}
            <IonButton
              color='secondary'
              expand='block'
              shape='round'
              size='large'
              fill='outline'
              onClick={() => props.setSteps(0)}
            >
              Back
            </IonButton>
          {/* )} */}
        </div>
      </form>
    </Container>
  );
};
