import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import { getSubjectList } from 'api/api';
import React, { useEffect, useState } from 'react';
import { AuthPage } from '../../../../components';
import { style } from './../../../../Utility';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

export const Notes = () => {
  const history = useHistory();
  const [subjectList, setSubjectList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(()=> {
    getSubjectList().then(res => {
      const {data, status} = res;
      if(status) {
        setSubjectList(data.data)
      }
      setLoader(false)
    })
  }, [])
  console.log(subjectList, "listtttttttttttt")
  if(loader)
    return <CircularProgress />
  return (
    <>
    <SideMenu/>
    <AuthPage containerWidth='sm' title='Notes' showBackButton>
      <div
        className='ion-padding'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div style={{ width: style.maxWidth }}>
          <p className='heading'>Please select your preferred subjcet</p>
          <Stack sx={{ alignItems: 'center' }} spacing={2}>
            {subjectList.map((language) => (
              <IonButton
                style={{ minWidth: 'calc(100% - 40px)', height: '47px' }}
                shape='round'
                color='primary'
                expand='block'
                onClick={() => history.push('/s/notes/list/'+language.id)}
              >
                {language.name}
              </IonButton>
            ))}
          </Stack>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
