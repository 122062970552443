import './index.scss';
import React, {useEffect, useState} from 'react';
import { IonAvatar, IonButton, IonIcon } from '@ionic/react';
import { School as SchoolIcon } from '@mui/icons-material';
import { cameraOutline } from 'ionicons/icons';
import { GiTeacher } from 'react-icons/gi';
import { profilePhoto } from '../../api/api';
import CircularProgress from '@mui/material/CircularProgress';

export interface StudentDashboardCardProps {
  name: string | React.ReactNode;
  id?: string | React.ReactNode;
  title?: string | React.ReactNode;
  onView?: Function;
  isActive?: boolean;
  showCamera?: boolean;
  showView?: boolean;
  icon?: string;
  isTeacher?: boolean;
  image?: string;
}

export const StudentDashboardCard = (props: StudentDashboardCardProps) => {
  const [loader, setLoader] = useState(true);

  const [profile, setProfile] = useState(props?.image)
  console.log("profile", props)
  const changeProfile = async (e) => {
    console.log(e.target.files[0]);
    setProfile(URL.createObjectURL(e.target.files[0]));
    await profilePhoto(e.target.files[0]).then(()=>{
      console.log("comingggggg")
    });
  }
  useEffect(()=>{
    if(!profile) {
      setLoader(true);
      let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
    } else {
      setLoader(true);
    }
  },[profile])
  
  if(!profile)
    return <CircularProgress />
  return (
    profile ? 
    <div style={{  paddingBottom: '0.5rem' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IonAvatar class='student-avatar'>
          {profile ? 
            <img src={profile} /> :
            props.isTeacher ? (
            <GiTeacher
              style={{ width: '16rem', height: '6rem', fontSize: '5rem' }}
            />
          ) : (
            <SchoolIcon
              style={{ width: '16rem', height: '6rem', fontSize: '5rem' }}
            />
          )}
        </IonAvatar>
        {props.isActive && <span className='activeBadge' />}
        {props.showCamera && (
          <div className="image-upload">
            <label htmlFor="file-input">
              <span className='cameraBadge'>
                <IonIcon style={{ paddingTop: '1.5px' }} icon={cameraOutline} />
              </span>
            </label>
            <input id="file-input" onChange={(e)=>{changeProfile(e)}} type="file" style={{'display':'none'}} />
          </div>
        )}
      </div>
      {props.id ? <h6 className='studentId'>{props.id}</h6> : undefined}
      {props.name ? <h5 className='studentName'>{props.name}</h5> : undefined}
      {props.title ? <div className='class'>{props.title}</div> : undefined}
      {props.showView && (
        <div>
          <IonButton
            fill='solid'
            shape='round'
            expand='full'
            className='view'
            onClick={(_e: any) => (props.onView ? props.onView(_e) : undefined)}
          >
            {' '}
            View{' '}
          </IonButton>
        </div>
      )}
    </div> : <h1>hello</h1>
  );
};