import classes from './index.module.scss';
import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Header } from '../../components';
import { useHistory } from 'react-router';
import { arrowUndoSharp } from 'ionicons/icons';
import { getOtpOnEmail } from 'api/api';
import CircularProgress from '@mui/material/CircularProgress';


export const ForgotPassword = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const submitForm = () => {
    setLoader(true);
    if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(forgetEmail)) {
      setEmailError("Enter valid email");
    } else {
      setEmailError("");
      getOtpOnEmail({email:forgetEmail})
        .then((response)=>{
          console.log(response,"ressssssssssssss");
          history.push({
            pathname: "./enter-otp",
            state: { data: forgetEmail },
          });
        })
    }
    setLoader(false);
  }
  if(loader)
    return <CircularProgress />
  return (
    <IonPage>
      <IonContent className={classes['login-form']} fullscreen>
        <Header icon={arrowUndoSharp} onIconClick={() => history.goBack()} />
        <form className={`ion-padding `} style={{ paddingTop: '10%' }}>
          <div
            className='ion-padding'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div style={{ maxWidth: '60%' }}>
              <Stack spacing={3} direction='column' justifyContent='center'>
                <IonToolbar>
                  <Typography
                    variant='h5'
                    style={{ fontWeight: 'bolder', fontSize: 'xx-large' }}
                  >
                    <FormattedMessage id='forgot_password.title' />
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 'bolder', color: 'gray' }}
                  >
                    <FormattedMessage id='forgot_password.sub_title' />
                  </Typography>
                </IonToolbar>
                <div>
                  <TextField
                    label='Email'
                    type='email'
                    id='outlined-size-small'
                    size='medium'
                    fullWidth
                    color='primary'
                    onChange={(e)=>setForgetEmail(e.target.value)}
                  />
                  <p style={{color:"red"}}>{emailError}</p>
                </div>
                <div>
                  <IonButton
                    className='ion-margin-top'
                    expand='full'
                    shape='round'
                    size='large'
                    onClick={()=>submitForm()}
                  >
                    <FormattedMessage id='forgot_password.button_text' />
                  </IonButton>
                </div>
              </Stack>
            </div>
          </div>
        </form>
      </IonContent>
      <IonFooter>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'gray',
            padding: '0',
            paddingBottom: '3rem',
          }}
        >
          <FormattedMessage id='forgot_password.declaration' />
        </Typography>
      </IonFooter>
    </IonPage>
  );
};
