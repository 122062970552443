import "./authpage.scss";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowUndoSharp,
  arrowBackOutline,
  menu as menuIcon,
  shareSocial,
} from "ionicons/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import watermark from "./../../assets/watermark.svg";
import { Breakpoint, Container } from "@mui/material";
import { home, person, notifications } from "ionicons/icons";
import { protectedRoute } from "../../Utility";

export interface AuthPageProps {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  showBackButton?: boolean;
  menu?: boolean;
  containerWidth?: Breakpoint;
  rightAlignTopItem?: React.ReactNode;
  shareButton?: boolean;
}

export const AuthPage = ({ menu = true, ...props }: AuthPageProps) => {

  const history = useHistory();

  useEffect(() => {
    protectedRoute()
  }, []);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          {props.showBackButton && (
            <IonButtons style={{position: 'absolute'}} slot="start">
              <IonIcon
                style={{
                  verticalAlign: "middle",
                  fontSize: "2rem",
                  //   float: 'left',
                }}
                icon={arrowBackOutline}
                onClick={() => history.goBack()}
              />
            </IonButtons>
          )}
          <IonTitle class="ion-text-center ion-text-uppercase">
            {props.title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="watermark">
        <img src={watermark} className="hideOnWeb" />
        <div className={"login-form wrapper fullScreen"}>
          <div className="flex spaceHorizontal ion-align-items-center ion-justify-content-between">
            <div className="">
              <IonMenuButton slot="start" title="Login" className="menuBtn">
                <IonIcon style={{ fontSize: "xx-large" }} icon={menuIcon} />
              </IonMenuButton>
            </div>
            {/* 
              {window.innerWidth > 416 && (
              <div className="ion-text-right flex gap">
                <IonIcon icon={notifications} size="large"></IonIcon>
                <IonIcon icon={home} size="large"></IonIcon>
                <IonIcon icon={person} size="large"></IonIcon>
              </div>
            )} */}
            {window.innerWidth < 416 && props.shareButton && (
              <IonButtons slot="end">
                <IonIcon
                  style={{
                    verticalAlign: "middle",
                    fontSize: "2rem",
                    //   float: 'left',
                  }}
                  icon={shareSocial}
                  onClick={() => history.goBack()}
                />
              </IonButtons>
            )}
          </div>
          <div className="form">
            <div className="pageMargin">
              <Container maxWidth={props.containerWidth} className="fullScreen">
                {props.children}
              </Container>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
