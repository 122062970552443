import './timetable.scss';
import React, { useEffect, useState } from 'react';
import { AuthPage } from '../../../../components';
import { Typography } from '@mui/material';
import { style } from './../../../../Utility';
import CircularProgress from '@mui/material/CircularProgress';
import { teacherTimetable } from 'api/api';
import { SideMenu } from "App/ITCTeacher/Components";
import * as dayjs from "dayjs"
import * as weekday from "dayjs/plugin/weekday"
dayjs.extend(weekday)

export const TimeTable = () => {
  var week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [loader, setLoader] = useState(true);
  const [timetableData, setTimetableData]: any = useState({});

  useEffect(()=>{
    teacherTimetable().then(res => {
      const {data, status} = res;
      if(status) {
        setTimetableData(data.data)
      }
      setLoader(false)

    })
  }, [])
  
  console.log(timetableData,"my teacher time table timetable")

  if(loader)
    return <CircularProgress />
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage containerWidth='xl' title='Time Table' showBackButton>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='ion-padding' style={{ width: '100%' }}>
          <p className='color-tertiary heading'>{week_days[dayjs().weekday()]}{dayjs().format(" (DD/MM/YYYY)")}</p>
          <div className='simpleTable timetable'>
            <table className='table' style={{width: "50%", margin: "auto"}}>
              <thead>
                <tr>
                  <th></th>
                  <th>{week_days[dayjs().weekday()]}</th>
                </tr>
              </thead>
              <tbody>
                {timetableData.length && timetableData.map((oneDayData, key)=>(
                  <tr>
                    <td>{oneDayData.start_time + " - " + oneDayData.end_time}</td>
                    <td>
                      <span
                        className='color-secondary'
                        style={{ textDecoration: 'underline' }}
                      >
                        {oneDayData.subject}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
