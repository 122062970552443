import './index.scss';
import React from 'react';
import { ButtonProps } from '@mui/material';

export const Awesome3DButton = (props: ButtonProps) => {
  return (
    <div className='awesome-3d-button'>
      <button onClick={props.onClick} className='learn-more'>
        {props.children}
      </button>
    </div>
  );
};
