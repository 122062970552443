export const messages = {
    required: {
        fname: "First Name is required",
        lname: "Last Name is required",
        full_name: "Full Name is required",
        mobile_no: "Enter valid mobile number",
        password: "Password is required",
        confirm_password: "Confirm Password is required",
        parent_mobile_no: "Parents mobile number required",
        login_email_mobile: "Email or mobile number required"
    },
    regex: {
        name: "Enter Only characters and space is allowed",
        mobile_no: "Enter valid mobile number",
        password: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    }
}