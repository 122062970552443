import { Dispatch } from "redux";

export const USER_LOGGED_TYPE = "USER_LOGGED_TYPE";

export const setLoggedUserType = (userType: string) => (dispatch: Dispatch) => {
  console.log("called");
  dispatch({
    type: USER_LOGGED_TYPE,
    payload: {
      userType,
    },
  });
};
