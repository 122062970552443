import { Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { AuthPage, Awesome3DButton } from "../../../../components";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from "App/ITCStudent/Components";

export const IndividualSubjects = () => {
  const history = useHistory();
  return (
    <>
    <SideMenu/>
    <AuthPage title="Enroll Subjects" showBackButton containerWidth="sm">
      <div style={{ paddingTop: "5rem" }} className="ion-padding">
        <Stack spacing={5} sx={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Awesome3DButton onClick={() => history.push("/s/enroll-subject1")}>
              Individual Package (30 DAYS)
            </Awesome3DButton>
            <Typography sx={{ textAlign: "center" }}>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 40</b> / one subject.
              </div>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 30</b> / one subject.
              </div>
              <div>Applies when enroll two or more subjects</div>
            </Typography>
          </div>
          <div>
            <Awesome3DButton onClick={() => history.push("/s/enroll-subject1")}>
              Individual Package (15 DAYS)
            </Awesome3DButton>
            <Typography sx={{ textAlign: "center" }}>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 20</b> / one subject.
              </div>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 15</b> / one subject.
              </div>
              <div>Applies when enroll two or more subjects</div>
            </Typography>
          </div>
        </Stack>
      </div>
    </AuthPage>
    </>
  );
};
