import { IonIcon } from '@ionic/react';
import { Box, Typography } from '@mui/material';
import { arrowBack, arrowForward, shareSocial } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { AuthPage } from '../../../../components';
import CircularProgress from '@mui/material/CircularProgress';
import { getUpcomingProgram } from '../../../../api/api';
import { Swiper, SwiperSlide } from "swiper/react";
import { SideMenu } from "App/ITCTeacher/Components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

export const UpcomingPrograms = () => {
  const [loader, setLoader] = useState(true);
  const [program_data, setProgramData]: any = useState({});

  useEffect(()=>{
    getUpcomingProgram().then(res => {
      const {data, status} = res;
      if(status) {
        setProgramData(data.data)
      }
      setLoader(false)
    })
    
  }, [])

  console.log("sdvjhdsbvhjsbhvjb" ,program_data)

  if(loader)
  return <CircularProgress />
  
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage
      title='Upcoming Programs'
      shareButton
      rightAlignTopItem={
        <IonIcon
          style={{
            fontSize: 'xx-large',
            verticalAlign: 'middle',
            padding: '.4rem',
          }}
          icon={shareSocial}
        />
      }
      showBackButton
    >
      <div className='ion-padding'>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {program_data.map((singleProgram)=>(
            <SwiperSlide><img src={singleProgram.image} style={{width:'100%'}} /></SwiperSlide>
          ))}
        </Swiper>
      </div>
    </AuthPage>
    </>
  );
};
