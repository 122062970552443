
import {
  Announcement,
  Attendance,
  ComboPackage,
  ContactUs,
  Feedback,
  IndividualPackage,
  IndividualSubjects,
  Notes,
  Package,

  Payment,
  Profile,
  StartClass,
  StudentProgress,
  TeachersProfile,
  TimeTable,
  UpcomingLessons,
  UpcomingPrograms,
  WelcomeEducator,
  Invoice,

} from "./Pages";
import { Recording } from "./Pages/Recording";
import { Salary } from "./Pages/Salary";

export interface AppRoutingOptions {
  path: string;
  exact: boolean;
  Component: React.FunctionComponent;
}

export const TeacherAppRoutes: AppRoutingOptions[] = [
  {
    path: "/teacher",
    exact: true,
    Component: TeachersProfile,
  },
  {
    path: "/welcome-educator",
    exact: true,
    Component: WelcomeEducator,
  },
  {
    path: "/recording",
    exact: true,
    Component: Recording,
  },
  {
    path: "/profile",
    exact: true,
    Component: Profile,
  },
  {
    path: "/attendance",
    exact: true,
    Component: Attendance,
  },
  {
    path: "/announcement",
    exact: true,
    Component: Announcement,
  },
  {
    path: "/payment",
    exact: true,
    Component: Payment,
  },
  {
    path: "/salary",
    exact: true,
    Component: Salary,
  },
  {
    path: "/combo-package",
    exact: true,
    Component: ComboPackage,
  },
  {
    path: "/individual-subjects",
    exact: true,
    Component: IndividualSubjects,
  },
  {
    path: "/package",
    exact: true,
    Component: Package,
  },
  {
    path: "/individual-package",
    exact: true,
    Component: IndividualPackage,
  },
  {
    path: "/feedback",
    exact: true,
    Component: Feedback,
  },
  {
    path: "/contact-us",
    exact: true,
    Component: ContactUs,
  },
  {
    path: "/start-class",
    exact: true,
    Component: StartClass,
  },
  {
    path: "/upcoming-lessons",
    exact: true,
    Component: UpcomingLessons,
  },
  {
    path: "/notes",
    exact: true,
    Component: Notes,
  },

  {
    path: "/time-table",
    exact: true,
    Component: TimeTable,
  },
  {
    path: "/student-progress",
    exact: true,
    Component: StudentProgress,
  },
  {
    path: "/upcoming-programs",
    exact: true,
    Component: UpcomingPrograms,
  },
  {
    path: "/t/invoice",
    exact: true,
    Component: Invoice,
  },

  {
    path: "/t/announcement",
    exact: true,
    Component: Announcement,
  },
];
