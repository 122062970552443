import {
  Login,
  ForgotPassword,
  Registeration,
  Signup,
  StudentInfo,
  ParentInfo,
  ITCStudent,
  ITCTeacher,
  EnterOtp,
  NewPassword,
} from "./pages";

import {
  Announcement,
  Attendance,
  ComboPackage,
  ContactUs,
  Feedback,
  IndividualPackage,
  IndividualSubjects,
  Notes,
  Package,
  Payment,
  Profile,
  StartClass,
  StudentProgress,
  TeachersProfile,
  TimeTable,
  UpcomingLessons,
  UpcomingPrograms,
  WelcomeEducator,
  Invoice,
  Success
} from "./App/ITCTeacher/Pages";
import { Recording } from "./App/ITCTeacher/Pages/Recording";
import { Salary } from "./App/ITCTeacher/Pages/Salary";
import TabRootPage from "./App/TabRootPage";

export interface AppRoutingOptions {
  path: string;
  exact: boolean;
  Component: React.FunctionComponent;
}

export const AppRoutes: AppRoutingOptions[] = [
  {
    path: "/login",
    exact: true,
    Component: Login,
  },
  {
    path: "/signup",
    exact: true,
    Component: Signup,
  },
  {
    path: "/forgot-password",
    exact: true,
    Component: ForgotPassword,
  },
  {
    path: "/student-info",
    exact: true,
    Component: StudentInfo,
  },
  {
    path: "/teacher-registeration",
    exact: true,
    Component: Registeration,
  },
  {
    path: "/parents-info",
    exact: true,
    Component: ParentInfo,
  },
  {
    path: "/student",
    exact: true,
    Component: ITCStudent,
  },
  {
    path: "/teacher",
    exact: true,
    Component: ITCTeacher,
  },
  {
    path: "/tabs",
    exact: false,
    Component: TabRootPage,
  },
  {
    path: "/enter-otp",
    exact: false,
    Component: EnterOtp,
  },
  {
    path: "/new-password",
    exact: false,
    Component: NewPassword,
  },
  {
    path: "/tabs/notifications",
    exact: true,
    Component: TabRootPage,
  },
  {
    path: "/tabs/home",
    exact: true,
    Component: TabRootPage,
  },
  {
    path: "/t/profile",
    exact: true,
    Component: TeachersProfile,
  },
  {
    path: "/t/welcome-educator",
    exact: true,
    Component: WelcomeEducator,
  },
  {
    path: "/t/recording",
    exact: true,
    Component: Recording,
  },
  {
    path: "/t/profile",
    exact: true,
    Component: Profile,
  },
  {
    path: "/t/attendance",
    exact: true,
    Component: Attendance,
  },
  {
    path: "/t/payment",
    exact: true,
    Component: Payment,
  },
  {
    path: "/t/invoice",
    exact: true,
    Component: Invoice,
  },
  {
    path: "/t/salary",
    exact: true,
    Component: Salary,
  },
  {
    path: "/t/combo-package",
    exact: true,
    Component: ComboPackage,
  },
  {
    path: "/t/individual-subjects",
    exact: true,
    Component: IndividualSubjects,
  },
  {
    path: "/t/package",
    exact: true,
    Component: Package,
  },
  {
    path: "/t/individual-package",
    exact: true,
    Component: IndividualPackage,
  },
  {
    path: "/t/feedback",
    exact: true,
    Component: Feedback,
  },
  {
    path: "/t/contact-us",
    exact: true,
    Component: ContactUs,
  },
  {
    path: "/t/start-class",
    exact: true,
    Component: StartClass,
  },
  {
    path: "/t/upcoming-lessons",
    exact: true,
    Component: UpcomingLessons,
  },
  {
    path: "/t/notes",
    exact: true,
    Component: Notes,
  },

  {
    path: "/t/time-table",
    exact: true,
    Component: TimeTable,
  },
  {
    path: "/t/student-progress",
    exact: true,
    Component: StudentProgress,
  },
  {
    path: "/t/upcoming-programs",
    exact: true,
    Component: UpcomingPrograms,
  },
  {
    path: "/t/announcement",
    exact: true,
    Component: Announcement,
  },
  {
    path: "/payment/success",
    exact: true,
    Component: Success,
  },
];

const isLoggedIn = (): boolean => {
  return true;
};

(() => {
  if (isLoggedIn())
    AppRoutes.push({
      path: "/",
      exact: true,
      Component: Login,
    });
  else
    AppRoutes.push({
      path: "/",
      exact: true,
      Component: Login,
    });
})();
