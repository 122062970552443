import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AuthPage } from '../../../../components';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { style } from './../../../../Utility';
import { getAnnouncementsTeacher } from '../../../../api/api';
import './announcement.scss';
// import { SideMenu } from "App/ITCTeacher/Components";

import { SideMenu } from 'App/ITCTeacher/Components';

export const Announcement = () => {

  const [announcementData, setAnnouncementData] = useState([]);
  useEffect(() => {
    getAnnouncementsTeacher().then(res => {
      setAnnouncementData(res.data.data);
    })
  }, [])
  return (
    <>
      <SideMenu />
      <AuthPage title='Announcement' showBackButton containerWidth='md'>
        {announcementData.map((data) => {
          return (<div className='ion-padding verticalSpace'>
            <Box>
              <Box className='border'>
                <p className='annouoncementHeading'>
                  <CelebrationIcon sx={{ verticalAlign: 'middle' }} />
                  {data.title}
                </p>
              </Box>
              <Box className='imageborder'>
                {/* sx={{
              height: '15rem',
              border: '1px solid black',
              marginTop: '-1px',
              padding: '1.5rem',
            }} */}

                <Box
                  sx={{
                    background: 'lightgray'
                  }}
                >
                  <img src={data.image} style={{
                    height: '500px',
                    width: "100%"
                  }}>
                  </img>
                </Box>
              </Box>
            </Box>
          </div>)
        })}
      </AuthPage>
    </>

  );
};
