import { IonIcon } from "@ionic/react";

import React from "react";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { search, home } from "ionicons/icons";
import { TextField } from "@mui/material";
// import { SideMenu } from "App/ITCTeacher/Components";

interface NotificationProps {}

export const Notification = (props: NotificationProps) => {
  const [searchText, setSearchText] = React.useState("");
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title="Notifications" containerWidth="md">
      <div className="ion-padding">
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ textAlign: "center" }}
              label="Search"
              id="outlined-size-small"
              size="medium"
              value={searchText}
              onChange={(e) => {
                console.log(e.target.value);
                setSearchText(e.target.value);
              }}
              color="primary"
            />
            <IonIcon className="search-icon" icon={search} />
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <div className="simpleTable">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <IonIcon icon={home} />
                      <p>CLASS LINK FOR BAHASA</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <IonIcon icon={home} />
                      <p>CLASS LINK FOR BAHASA</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
