import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './class.scss';
import { AuthPage, ClassDetails } from '../../../../components';
import { GET } from 'api/request';
import * as dayjs from "dayjs"
import * as weekday from "dayjs/plugin/weekday"
import { useHistory } from "react-router";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

import CircularProgress from '@mui/material/CircularProgress';
dayjs.extend(weekday)
export const JoinClasses = () => {
  const history = useHistory();

  const handelClick = (value: any) => {
    window.location.href = value
  }
  let [loader, setLoader] = useState(true);
  let [classDetail, setClassDetail]: any = useState([])
  var week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    GET("student/classes/current")
      .then((response: any) => {
        const { data, status } = response
        if (status) {
          setClassDetail(data.data.data);
        }
        setLoader(false)
      })
      .catch((error: any) => {
        setLoader(false);
        setClassDetail([]);
        console.log(error)
      })
    console.log("my student join class" + JSON.stringify(classDetail))
  }, [])


  return (
    <>
      <SideMenu />
      <AuthPage title='Join Class' showBackButton containerWidth='sm'>
        <div className='io-padding' style={{ textAlign: 'center' }}>
          <h4 className='color-tertiary classHeading'>{week_days[dayjs().weekday()]}{dayjs().format(" (DD/MM/YYYY)")}</h4>
          {classDetail.subject_name ?
            <div className='classCard'>
              <ClassDetails subject={classDetail.subject_name} timing={classDetail.actual_start_time + "-" + classDetail.actual_end_time} classLink={classDetail.link} />
              {/* <ClassDetails subject='BHASA TAMIL' timing='8PM - 9PM' /> */}
            </div>
            : "No current class"
          }
        </div>
      </AuthPage>
    </>
  );
};
