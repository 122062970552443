import { IonButton } from "@ionic/react";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { AuthPage, LanguageBox } from "../../../../components";
import { LanguageOptions } from "../../../../components/LanguageBox";
import "./style.scss";
// import { SideMenu } from "App/ITCTeacher/Components";
import { useLocation } from "react-router-dom";
import { getEnrollSubject } from "api/api";
import { getPackageCategory } from '../../../../api/api';
import { SideMenu } from "App/ITCStudent/Components";

export const EnrollSubjects1 = (props) => {

  const [total, settotal] = useState(0);
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [checkedState, setCheckedState] = useState([]);
  const [amount, setAmount] = useState(location.state);
  const [languages, setlanguages] = useState([]);
  console.log("languagess:>>>>>>>>>>>", languages)
  var totaltrue = 0;
  const handleCheck = (event: number, index: number) => {
    languages[event][index].checked = !languages[event][index].checked
    if (languages[event][index].checked) {
      languages.map((item) => {
        if (item.length <= 1) {
          if (item[0].checked) {
            totaltrue = totaltrue + 1;
            settotal(totaltrue)
          }
        } else {
          var data = item;
          data.map((value) => {
            if (value.checked) {
              totaltrue = totaltrue + 1;
              settotal(totaltrue)
            }
          })
        }
      })
    } else {
      settotal(total - 1);
    }

  };
  const handelPaynow = () => {
    let selectedId = [];
    languages.map((language) => {
      if (language[0].checked) {
        selectedId.push(language[0].id);
      }
    })
    console.log("select:>>>>", selectedId);
    if (selectedId.length) {
      history.push({
        pathname: "/s/payment",
        state: {
          totalAmount: total == 1 ? amount['single'] * total : amount['multiple'] * total,
          package_id: amount["package"],
          package_category_id: amount["category"],
          subject_ids: selectedId
        }
      })
      setError("");
    } else {
      setError("Select atleast one subject")
    }
  }

  useEffect(() => {
    getEnrollSubject(amount["package"], amount["category"]).then(res => {
      const { data, status } = res;
      if (status) {
        console.log("data:>>>>>>>>>", data);
        setlanguages(data)
      }
      // setLoader(false)
    })
  }, [])


  return (
    <>

      <SideMenu />

      <AuthPage title="Combo Package" showBackButton containerWidth="md">
        <p className="subjectHeading">Please Select your preferred subjects</p>

        <div>
          {languages.map((language, indexdata) => (
            <LanguageBox
              title={language.length > 1 ? "SCIENCE" : undefined}
              languages={language}
              indexdata={indexdata}
              handleCheck={handleCheck}
            />
          ))}
        </div>

        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <h2>Total Amount : {total == 1 ? amount['single'] * total : amount['multiple'] * total}  </h2>
          <IonButton
            onClick={handelPaynow}

            className="continue"
            shape="round"
            color="secondary"
          >
            Pay Now
          </IonButton>
          <br />
          <div style={{ "color": "red" }}> {error}</div>
        </div>
      </AuthPage >
    </>
  );
};

function setLoader(arg0: boolean) {
  throw new Error("Function not implemented.");
}

