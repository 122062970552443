import React from 'react'
import logoSvg from "./../../assets/ITCLogo.png";
import teacher from "./../../assets/img/teacher.png";
import student from "./../../assets/img/student.png";
import { IonButton } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

function SelectType({ setLoginType }) {
    return (
        <div>
            <div className="logo">
                <img src={logoSvg} alt="" />
            </div>

            <div className='selectlogin'>

                <div>
                    <img src={student} alt="" onClick={() => { setLoginType("student") }} height={200} />

                </div>
                <div>

                    <img src={teacher} alt="" onClick={() => { setLoginType("teacher") }} height={200} />

                </div>
            </div>
        </div>

    )
}

export default SelectType