import { IonButton } from '@ionic/react';
import { Stack, TextField } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { AuthPage } from '../../../../components';
import { style } from './../../../../Utility';
import { POST } from "../../../../api/request";
import { toast } from "react-toastify";
import { feedbackTeacher } from '../../../../api/api';
import { useHistory } from 'react-router';

// import { SideMenu } from "App/ITCTeacher/Components";

export const Feedback = () => {

  const history = useHistory();

    let [formData, setFormData] = useState({
    "name":"",
    "message":""
  });
  
  const submitFeedback = async () => {
    try {
      const response = await feedbackTeacher( formData );
      
      if (response.status) {
        toast.success("Feedback submited successfully");
        setFormData({
          "name":"",
          "message":""
        })
        history.push('/t/feedback')
      } else {
        toast.success("Feedback not submited successfully");
      }
    } catch (error) {
        toast.success("Feedback error submited successfully");
    }
  }

  return (
    <>
    
    {/* <SideMenu/> */}
    
    <AuthPage title='Feedback' showBackButton containerWidth='sm'>
  
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
        <div className='ion-padding' style={{ paddingTop: '4rem', width: style.maxWidth }}>
          
          <Stack spacing={4}>
            <TextField label='Title' onChange={(e)=>setFormData({...formData, name:e.target.value})} value={formData.name} size='small' fullWidth color='primary'  />
            <TextField
              label='Description'
              size='small'
              fullWidth
              color='primary'
              multiline
              rows={10}
              onChange={(e)=>setFormData({...formData, message:e.target.value})} 
              value={formData.message}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IonButton color='secondary' shape='round' onClick={submitFeedback}>
                Submit
              </IonButton>
            </div>
          </Stack>

        </div>
      </div>
    </AuthPage>
    </>
  );
};