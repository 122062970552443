import { IonButton } from '@ionic/react';
import { Stack, TextField } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { AuthPage } from '../../../../components';
import { style } from './../../../../Utility';
import { toast } from "react-toastify";
import {feedbackStudent} from '../../../../api/api';
import { useHistory } from 'react-router';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

export const Feedback = () => {

  const history = useHistory();

  let [formData, setFormData] = useState({
    "name":"",
    "message":""
  });
  
  const submitFeedback = async () => {
    try {
      const response = await feedbackStudent( formData );
  
      if (response.status) {
        toast.success("Feedback submited successfully");
        setFormData({
          "name":"",
          "message":""
        })
        history.push('/s/feedback')
      } else {
        toast.success("Feedback not submited successfully");
      }
    } catch (error) {
      toast.success("Feedback error submited successfully");
    }
  }

  return (
    <>
    <SideMenu/>
    
    <AuthPage title='Feedback' showBackButton containerWidth='md'>
      <div className='fullScreen ion-padding'>
        <div className='coverHeight ion-justify-content-center flex column'>
          <Stack spacing={4}>
            <TextField label='Title' onChange={(e)=>setFormData({...formData, name:e.target.value})} value={formData.name} size='small' fullWidth color='primary'  />
            <TextField
              label='Description'
              size='small'
              fullWidth
              color='primary'
              multiline
              rows={10}
              onChange={(e)=>setFormData({...formData, message:e.target.value})} 
              value={formData.message}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IonButton color='secondary' shape='round' onClick={submitFeedback}>
                Submit
              </IonButton>
            </div>
          </Stack>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
