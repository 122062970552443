import "./index.scss";
import React, { useState, useEffect } from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import { Container, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import watermark from "./../../assets/watermark.svg";
import logoSvg from "./../../assets/ITCLogo.png";
import { protectedRoute, style } from "./../../Utility";
import { useFormik } from "formik";
import * as yup from "yup";
import { messages } from "../../Utility/messages";
import { TextInput } from "../../App/ITCTeacher/Components/inputFields";
import { validate } from "../../api/auth";
import { useLocation } from "react-router-dom";

interface SignupProps { }

export const Signup = (props: SignupProps) => {
  const location = useLocation<any>();

  const history = useHistory();
  const name_regex = /^[a-z A-Z]+$/i
  const mobile_regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
  const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  useEffect(() => {
    protectedRoute(false)

  }, [])


  const SignupSchema = yup.object({
    fname: yup.string().required(messages.required.fname)
      .matches(name_regex, messages.regex.name),
    lname: yup.string().required(messages.required.lname).matches(name_regex, messages.regex.name),
    mobile_no: yup.string().required(messages.required.mobile_no)
      .test('Unique Mobile', 'This Mobile is already in use', // <- key, message
        async (value) => {
          return value && value.length > 9 ? validate({ mobile_no: value }).then(res => {
            if (res.status) {
              return true
            } else {
              return false
            }
          }) : true
        }
      )
      .matches(mobile_regex, messages.regex.mobile_no),
    password: yup.string().required(messages.required.password)
      .matches(password_regex, messages.regex.password),
    confirm_password: yup.string().required(messages.required.confirm_password)
      .oneOf([yup.ref('password'), null], 'Password must match'),
  });

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      mobile_no: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log('values :>> ', values);
      // navigate("/teacher-registration", {state: values})
      console.log(location.state.data, "typeeeeeeeeeee");
      if (location.state.data == 'student') {
        history.push({
          pathname: "./student-info",
          state: { data: values },
        });
      } else {
        history.push({
          pathname: "./teacher-registeration",
          state: { data: values },
        });
      }
    },
  });


  return (
    <IonPage>
      <IonContent
        className="login-form ion-padding watermark signup"
        fullscreen
      >
        <img src={watermark} className="hideOnWeb" />
        <Container maxWidth="md" className="container">
          <form className="form-wrapper" onSubmit={formik.handleSubmit}>
            <Stack spacing={2} direction="column" justifyContent="center">
              <div className="logo">
                <img src={logoSvg} alt="" />
              </div>
              <TextInput formik={formik} label={"First Name"} field_name="fname" />
              <TextInput formik={formik} label={"Last Name"} field_name="lname" />
              <TextInput formik={formik} label={"Mobile Number"} field_name="mobile_no" />
              <TextInput formik={formik} label={"Password"} field_name="password" type="password" />
              <TextInput formik={formik} label={"Confirm Password"} field_name="confirm_password" type="password" />

              <Stack spacing={1}>
                <div className="space mob-mt-4">
                  <IonButton
                    type="submit"
                    color="secondary"
                    expand="full"
                    shape="round"
                    size="large"
                    className="signup"
                  >
                    <FormattedMessage id="signup.button_text" />
                  </IonButton>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "700",
                      paddingTop: ".75rem",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "none", fontSize: 20 }}
                      to="/login"
                    >
                      Back to Log In
                    </Link>
                  </Typography>
                </div>
              </Stack>
            </Stack>
          </form>
        </Container>
      </IonContent>
    </IonPage>
  );
};
