import "./index.scss";
import React,{useState, useEffect} from "react";
import { IonButton } from "@ionic/react";
import {
  AuthPage,
  StatusCard,
  StudentDashboardCard,
} from "../../../../components";
import { style } from "./../../../../Utility";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router";
import {SignOut} from "../../Components/SignOut/index";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';


interface StudentProfileProps {}

export const StudentProfile = (props: StudentProfileProps) => {
  // let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  // let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  const [profileData, setProfileData] :any = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    GET("student/profile").then((response: any) => {
      const {data, status} = response
      if (status) {
        setProfileData(data.data.data);
      }
      setLoader(false)
    });
  }, []);
  const history = useHistory();

  const onMenuCLick = async (path: string) => {
    history.push(path);
  };
  
  const [counter, setCounter] = React.useState<number>(1);
  
  if(loader)
    return <CircularProgress />
  
  return (
    <>
        <SideMenu/>
        <AuthPage title="VANAKKAM NANBA" containerWidth="xs" showBackButton>
          <div className="ion-padding-vertical">
            <StudentDashboardCard
              name={profileData.name}
              title={profileData.class? profileData.class : ""}
              image={profileData?.image}
              showCamera
              onView={() => history.push('/s/status')}
            />

            {/* {counter === 1 && (
              <StatusCard
                color="danger"
                status="Not Active"
                description="Please proceed to payment to avoid any interuption. Your account will be froze if no further action taken."
              />
            )}

            {counter === 2 && (
              <StatusCard
                color="warning"
                status="Active"
                description="Please make your payment before 24/07/2022 to avoid any interuptions."
              />
            )} */}

            {/* {counter === 3 && <StatusCard color="success" status="Active" active />} */}
            
            <StatusCard color="success" status="Active" active />
            <div className="paybtn">
              <IonButton
                color="primary"
                fill="solid"
                shape="round"
                size="large"
                onClick={() => {
                  setCounter(counter + 1);
                  if (counter > 2) setCounter(1);
                }}
              >
                {" "}
                {/* {counter === 3 ? "Join Classes" : "Pay Now"}{" "} */}
                Join Class
              </IonButton>
            </div>
            <SignOut page="/login" />
          </div>
        </AuthPage>
    </>
  );
};
