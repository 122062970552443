// import './index.scss';
import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
import { SideMenu } from "App/ITCTeacher/Components";
import { IonButton } from '@ionic/react';
import './welcome.scss';
import {
  AuthPage,
  StatusCard,
  StudentDashboardCard,
} from '../../../../components';
import './welcome.scss';
import { getTeacherProfile } from 'api/api';

export const WelcomeEducator = () => {
  const history = useHistory();
  const [teacherProfile, setTeacherProfile]: any = useState({});
  const [loader, setLoader] = useState(true);
  let [classDetail, setClassDetail] : any = useState([])

  console.log(teacherProfile.image, "oooo meri profile")
  
  useEffect(()=> {
    getTeacherProfile().then((response: any) => {
      const {data, status} = response
      if (status) {
        setTeacherProfile(data.data);
      }
      setLoader(false)
    });
  
    GET("teacher/classes/current")
    .then((response:any)=> {
      const {data, status} = response
      if (status) {
        setClassDetail(data.data.data);
      }
      setLoader(false)
    })
    .catch((error:any) => {
      setLoader(false);
      setClassDetail([]);
      console.log(error)
    })
  },[])

  console.log("my current class " , classDetail)

  const signout = () => {
    localStorage.removeItem("itc_token");
    localStorage.removeItem("profile");
    localStorage.removeItem("loggedUserType");
    localStorage.removeItem("persist:root");
    history.push("/login")
  }
  
  if(loader)
    return <CircularProgress />
  
    return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title='VANAKKAM GURU' showBackButton containerWidth='xs'>
      <StudentDashboardCard
        name={<span className='color-primary'>{teacherProfile.name}</span>}
        title={teacherProfile.role}
        image={teacherProfile.image}
        showCamera
        isTeacher
      />

      <StatusCard
        title='Current Lesson'
        color='warning'
        status={classDetail.subject_name}
        description={
          <div className='typography'>
            <p>{classDetail.standard_name}</p>
            <p className='color-primary'>{classDetail.actual_start_time} - {classDetail.actual_end_time}</p>
          </div>
        }
      />

      <div className='btnWrapper'>
        <IonButton
          color='primary'
          expand='full'
          fill='solid'
          shape='round'
          size='large'
          className='start-class'
          onClick={()=>{ window.location.href=classDetail.link}}
        >
          {' '}
          Start Class{' '}
        </IonButton>
        <IonButton
          expand='full'
          color='secondary'
          fill='solid'
          shape='round'
          className='signoutbtn'
          onClick={signout}
        >
          {' '}
          Sign out{' '}
        </IonButton>
      </div>
    </AuthPage>
    </>
  );
};
