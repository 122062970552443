import { Container, Stack, TextField, Typography } from "@mui/material";


export const TextInput  = ({formik, label, field_name, type = "text"}) => {
    let field_value = formik.values && formik.values[field_name];
    let field_error = formik.errors && formik.errors[field_name];
    return (
      <div>
        <TextField
          label={label}
          id="outlined-size-small"
          name={field_name}
          size="medium"
          fullWidth
          onChange={formik.handleChange}
          value={field_value}
          color="primary"
          type={type}
        />
        <span style={{ color: "red" }}>{field_error}</span>
      </div>
    );
  };