import { IonIcon } from "@ionic/react";

import React, { useEffect, useState } from "react";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { search, home } from "ionicons/icons";
import { TextField } from "@mui/material";
import { useHistory } from "react-router";
import { getNotification } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';


interface NotificationProps {}

export const Notification = (props: any) => {
  
  const [searchText, setSearchText] = React.useState("");
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [notificationList, setNotificationList] = useState([])
  
  useEffect(()=>{
    getNotification().then(res => {
      const {data, status} = res;
      if(status) {
        setNotificationList(data.data)
      }
      setLoader(false)
    })
  }, [])
  console.log(notificationList,"packageeeeeeeee");
  
  if(loader)
    return <CircularProgress />
  return (

    <>
    
<SideMenu/>
    <AuthPage title="Notifications" containerWidth="md">
      <div className="ion-padding">
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ textAlign: "center" }}
              label="Search"
              id="outlined-size-small"
              size="medium"
              value={searchText}
              onChange={(e) => {
                console.log(e.target.value);
                setSearchText(e.target.value);
              }}
              color="primary"
            />
            <IonIcon icon={search} />
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <div className="simpleTable">
            <table>
              <tbody>
                {notificationList.map((notification, index)=>(
                  <tr>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <IonIcon icon={home} />
                        <p>{notification.data.message}</p>
                      </div>
                    </td>
                  </tr>
                ))}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
