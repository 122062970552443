import classes from './index.module.scss';
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Header } from '../../components';
import { useHistory } from 'react-router';
import { arrowUndoSharp } from 'ionicons/icons';
import { checkOtp } from 'api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from "react-router-dom";


export const EnterOtp = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const [loader, setLoader] = useState(false);
  useEffect(()=> {
    if(typeof location.state == 'undefined') {
      history.push('/login')
    }
  },[])
  const [data, setData] = useState({
    "otp": 0,
    "email": location.state?.data
  })
  const [otpError, setOtpError] = useState("");
  const submitForm = () => {
    setLoader(true);
    if (data.otp < 100000 || data.otp > 999999) {
      setOtpError("Enter valid otp");
    } else {
      setOtpError("");
      checkOtp(data)
        .then((response) => {
          if(response){
            console.log(response, "ressssssssssssss");
            history.push({
              pathname: "./new-password",
              state: { data },
            });
          }
        })
    }
    setLoader(false);
  }
  console.log(data, "dataaaaaaaaaaaaaaa")
  if (loader)
    return <CircularProgress />
  return (
    <IonPage>
      <IonContent className={classes['login-form']} fullscreen>
        <Header icon={arrowUndoSharp} onIconClick={() => history.goBack()} />
        <form className={`ion-padding `} style={{ paddingTop: '10%' }}>
          <div
            className='ion-padding'
          >
            <div className='password-box'>
              <Stack spacing={3} direction='column' justifyContent='center'>
                <IonToolbar>
                  <Typography
                    variant='h5'
                    style={{ fontWeight: 'bolder', fontSize: 'xx-large' }}
                  >
                    <FormattedMessage id='forgot_password.otp' />
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 'bolder', color: 'gray' }}
                  >
                    <FormattedMessage id='forgot_password.otp_subtitle' />
                  </Typography>
                </IonToolbar>
                <div>
                  <TextField
                    label='OTP'
                    type='number'
                    id='outlined-size-small'
                    size='medium'
                    fullWidth
                    color='primary'
                    onChange={(e) => setData({ ...data, otp: parseInt(e.target.value) })}
                  />
                  <p style={{ color: "red" }}>{otpError}</p>
                </div>
                <div>
                  <IonButton
                    className='ion-margin-top'
                    expand='full'
                    shape='round'
                    size='large'
                    onClick={() => submitForm()}
                  >
                    <FormattedMessage id='forgot_password.check_otp' />
                  </IonButton>
                </div>
              </Stack>
            </div>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};
