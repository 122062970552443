import { POST } from "./request";
import { toast } from "react-toastify";

export const signup = async (data) => {
  try {
    const response = await POST("teacher/register", { data });

    if (response.data.status) {
      // localStorage.setItem("itc_token", response.data.token);
      toast.success("Register successfully");
      return response.data;
    } else {
      toast.success("Register successfully");
    }
  } catch (error) {
    toast.error("Invalid username and password");
  }
};

export const studentSignup = async (data) => {
  try {
    const response = await POST("student/register", { data });
    console.log(response,"responseeeeeeeeeeeeeeee")
    if (response.status) {
      // localStorage.setItem("itc_token", response.data.token);
      // localStorage.setItem("profile", response.data );
      toast.success("Register successfully");
      return response.data;
    } else {
      toast.success("Register successfully");
    }
  } catch (error) {
    toast.error("Invalid username and password");
  }
};

export const login = async (data,loginwith) => {
  try {
    const response = await POST("teacher/login", { data });
    console.log("responseeeeeeeeeeeeeeeeeeeee",response)
    if (response.data.status) {
      console.log("comingggggggggggggg")
      localStorage.setItem('loginwith',loginwith)
      localStorage.setItem("itc_token", response.data.token);
      localStorage.setItem("profile", JSON.stringify(response.data.data));
      localStorage.setItem("loggedUserType", response.data.data.role);
      console.log(response.data.data.role,"roleeeeeeeeeeeeeeee")
    } 

    return response.data;

  } catch (error) {
    //   toast.error("Invalid username and password");
    return error
  }

};

export const studentLogin = async (data,loginwith) => {
  try {
    const response = await POST("student/login", { data });
    console.log(response.data.data,"dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    if (response.data.status) {
      localStorage.setItem('loginwith',loginwith)
      localStorage.setItem("itc_token", response.data.token);
      localStorage.setItem("profile", JSON.stringify(response.data.data));
      localStorage.setItem("loggedUserType", response.data.data.role);
    } 

    return response.data;

  } catch (error) {
    //   toast.error("Invalid username and password");
    return error
  }

};

export const validate = async (data) => {
  try {
    const response = await POST("teacher/verify-email-or-number", { data });
   return response.data
  } catch (error) {
    // toast.error("Invalid username and password");
  }
};