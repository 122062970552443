import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { styled } from '@mui/material/styles';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import Stack from '@mui/material/Stack';
import * as utility from './../../Utility';
import { arrowBackOutline } from 'ionicons/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

import { getStatesList, getCityList } from 'api/api';
import * as yup from "yup";
import { protectedRoute, style } from "./../../Utility";
import { messages } from "../../Utility/messages";
import { useFormik } from "formik";
import { TextInput } from "../../App/ITCTeacher/Components/inputFields";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useLocation } from "react-router-dom";
import { studentSignup } from '../../api/auth';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 11px)',
    right: 'calc(50% + 11px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#AB1F23',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#AB1F23',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className='completed-circle' />
      ) : (
        <div className='circle' />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Student Information', 'Parent Information'];

interface ParentInfoProps { }

export const ParentInfo = (props: ParentInfoProps) => {
  const location = useLocation<any>();

  const [personName, setPersonName] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const history = useHistory();
  const name_regex = /^[a-z A-Z]+$/i
  const mobile_regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
  const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  useEffect(() => {
    protectedRoute(false)

  }, [])


  const SignupSchema = yup.object({
    parent_full_name: yup.string().required(messages.required.full_name)
      .matches(name_regex, messages.regex.name),
    parent_ic_no: yup.string().required(),
    relationship: yup.string().required(),
    parent_mobile_no: yup.string().required(messages.required.parent_mobile_no)
      .matches(mobile_regex, messages.regex.mobile_no),
    address_line1: yup.string().required(),
    address_line2: yup.string().required(),
    pincode: yup.string().required(),
    state_id: yup.string().required(),
    city_id: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      parent_full_name: "",
      parent_ic_no: "",
      relationship: "",
      parent_mobile_no: "",
      address_line1: "",
      address_line2: "",
      pincode: "",
      state_id: "",
      city_id: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      values = { ...location.state.data, ...values }
      // console.log('values :>> ', values);
      const signup_response = await studentSignup(values)
      history.push({
        pathname: "/",
        state: { data: values },
      });
    },
  });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '100%',
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const names = ['Father', 'Mother', 'Sister', 'Brother'];

  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);

  // fetching dynamic data
  useEffect(() => {
    // get states
    getStatesList().then(res => {
      console.log('getStatesList', res);
      const { data, status } = res;
      if (status) {
        setStatesData(data.data);
      }
    })
    // get cities
    getCityList().then(res => {
      console.log('getCityList', res);
      const { data, status } = res;
      if (status) {
        setCitiesData(data.data);
      }
    })
  }, [])

  return (
    <IonPage>
      <IonHeader class='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonIcon
              style={{
                verticalAlign: 'middle',
                fontSize: '2rem',
                //   float: 'left',
              }}
              icon={arrowBackOutline}
              onClick={() => history.goBack()}
            />
          </IonButtons>
          <IonTitle class='ion-text-center ion-text-uppercase'>
            Student Profile
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='studenInfo ion-padding-horizontal'>
        <Container maxWidth='md'>
          <div className='column flex'>
            <IonToolbar className='stickyStepper'>
              <Stepper
                alternativeLabel
                activeStep={2}
                connector={<QontoConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {/* <Stepper
              alternativeLabel
              activeStep={1}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper> 
            <Stepper activeStep={1} alternativeLabel>
              <Step key={'studentInfo'}>
                <StepLabel>{'student Information'}</StepLabel>
              </Step>
              <Step key={'parentsInfo'}>
                <StepLabel>
                  <FormattedMessage id='register.parent_stepper' />
                </StepLabel>
              </Step>
            </Stepper>*/}
            </IonToolbar>
            <h3 className='sectionHeading'>Parents Information</h3>
            <form className='infoForm' onSubmit={formik.handleSubmit}>
              <TextInput formik={formik} label={"Parent Full Name"} field_name="parent_full_name" />
              <TextInput formik={formik} label={"IC No"} field_name="parent_ic_no" />
              <FormControl>
                <InputLabel id='demo-multiple-name-label'>
                  Relationship
                </InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={formik.values.relationship}

                  onChange={formik.handleChange}
                  input={<OutlinedInput label='Name' />}
                  MenuProps={MenuProps}
                  name="relationship"
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <span style={{ color: "red" }}>{formik.errors.relationship}</span>
              </FormControl>
              <TextInput formik={formik} label={"Mobile No"} field_name="parent_mobile_no" />
              <TextInput formik={formik} label={"Address Line 1"} field_name="address_line1" />
              <TextInput formik={formik} label={"Address Line 2"} field_name="address_line2" />
              <TextInput formik={formik} label={"Postcode"} field_name="pincode" />
              <FormControl>
                <InputLabel id='state-field-label'>
                  State
                </InputLabel>

                <TextInput formik={formik} label={"State"} field_name="state_id" />
              </FormControl>

              {/* CITY LIST FIELD */}
              <FormControl>
                <InputLabel id='city-field-label'>
                  City
                </InputLabel>

                <TextInput formik={formik} label={"City"} field_name="city_id" />

                <span style={{ color: "red" }}>{formik.errors.state_id ? "City is required" : ""}</span>

              </FormControl>

              <div className="btnwrap">
                <IonButton
                  color="secondary"
                  expand="block"
                  shape="round"
                  size="large"
                  type="submit"
                // onClick={() => setButtonFn()}
                // onClick={formik.handleSubmit}
                >
                  <FormattedMessage id="signup.next_button" />
                </IonButton>
              </div>
              <IonButton
                color='secondary'
                fill='outline'
                shape='round'
                size='large'
                onClick={() => history.goBack()}
              >
                <FormattedMessage id='signup.back_button' />
              </IonButton>
            </form>
          </div>
        </Container>
      </IonContent>
    </IonPage>

    // <AuthPage
    //   title={<FormattedMessage id='register.parent_profile' />}
    //   menu={false}
    //   showBackButton
    // >
    //   <form className='ion-padding'>
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       <div style={{ width: style.maxWidth }}>
    //         <Stack spacing={1} direction='column' justifyContent='center'>
    //           <IonToolbar>
    //             <Stepper activeStep={1} alternativeLabel>
    //               <Step key={'studentInfo'}>
    //                 <StepLabel>
    //                   <FormattedMessage id='register.parent_stepper' />
    //                 </StepLabel>
    //               </Step>
    //               <Step key={'parentsInfo'}>
    //                 <StepLabel>{'Parents Information'}</StepLabel>
    //               </Step>
    //             </Stepper>
    //           </IonToolbar>
    //           <h3 className='sectionHeading'>Parent Information</h3>
    //           <div>
    //             <TextField
    //               label='Full Name'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='IC No'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='Relationship'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='Mobile No'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='Address Line1'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='Address Line2 [Optional]'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='Postcode'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='State'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               label='City'
    //               type='text'
    //               id='outlined-size-small'
    //               size="medium"
    //               fullWidth
    //               color='primary'
    //             />
    //           </div>
    //           <div style={{ display: 'flex', justifyContent: 'center' }}>
    //             <div style={{ paddingTop: '1rem', width: style.maxWidth }}>
    //               <Stack spacing={1}>
    //                 <IonButton
    //                   color='secondary'
    //                   expand='block'
    //                   shape='round'
    //                   size="large"
    //                   onClick={onSignup}
    //                 >
    //                   <FormattedMessage id='signup.next_button' />
    //                 </IonButton>
    //                 <IonButton
    //                   color='secondary'
    //                   expand='block'
    //                   fill='outline'
    //                   shape='round'
    //                   size="large"
    //                   onClick={() => history.goBack()}
    //                 >
    //                   <FormattedMessage id='signup.back_button' />
    //                 </IonButton>
    //               </Stack>
    //             </div>
    //           </div>
    //         </Stack>
    //       </div>
    //     </div>
    //   </form>
    // </AuthPage>
  );
};
