import "./index.scss";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonRouterOutlet,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { Stack, Typography } from "@mui/material";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import { SidebarButton } from "../SidebarButton";

export const SideMenu = () => {

  const history = useHistory();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  let loginwith = localStorage.getItem("loginwith") 
  let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  const onMenuCLick = async (path: string) => {
    history.push(path);
    setIsVisible(false);
  };
  const signout = () => {
    localStorage.removeItem("itc_token");
    localStorage.removeItem("profile");
    localStorage.removeItem("loggedUserType");
    localStorage.removeItem("persist:root");
    history.push("/login")
  }

  return (
    <>
      <IonMenu side="start" contentId="main">
        <IonHeader>
          <IonToolbar>
            <div className="manu-header">
              <IonTitle>{profileData.name}</IonTitle>
              <Typography>{(loginwith==='email')?profileData.email: profileData.mobile_no}</Typography>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent class="outer-content">
          <IonList className="button-list">
            <div className="classMenu">
              <div>
                <SidebarButton
                  color="secondary"
                  shape="round"
                  expand="block"
                  text="Enroll Subjects"
                  changePage={"/s/enroll-subject"}
                />
              </div>

              <div>
                <SidebarButton
                  color="secondary"
                  shape="round"
                  expand="block"
                  text="Payment"
                  changePage={"/s/payment-history"}
                />
              </div>

              <div>
                <SidebarButton
                  color="primary"
                  shape="round"
                  expand="block"
                  text="Profile"
                  style={{ textAlign: "left" }}
                  changePage={"/s/profile"}
                />
              </div>

              <div>
                <IonItem
                  className="menuItem"
                  color="primary"
                  style={{
                    marginTop: "2px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    borderBottomLeftRadius: isVisible ? "" : "25px",
                    borderBottomRightRadius: isVisible ? "" : "25px",
                    borderBottom: isVisible ? "1px solid white" : "",
                  }}
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <p className=" fullWidth"
                    style={{
                      textAlign: "center",
                      width: '100%',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    }}

                  >
                    Class{" "}
                    <IonIcon
                      style={{ verticalAlign: "middle", float: "right" }}
                      icon={isVisible ? caretUpOutline : caretDownOutline}
                    />
                  </p>
                </IonItem>
                {isVisible && (
                  <IonMenuToggle style={{ display: "contents" }}>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/join-class")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Join Class
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/recording")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Recording
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/attendance")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Attendance
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/notes")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Notes
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                      }}
                      onClick={() => onMenuCLick("/s/time-table")}
                    >
                      <p
                        style={{
                          marginTop: "0",
                          margin: 0,
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Timetable
                      </p>
                    </IonItem>
                  </IonMenuToggle>
                )}
              </div>
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/student-progress")}
                  >
                    Student Progress
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/feedback")}
                  >
                    Feedback
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/announcement")}
                  >
                    Announcements
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/upcoming-programs")}
                  >
                    Upcoming Programs
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/contact-us")}
                  >
                    Contact US
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/invite-friend")}
                  >
                    Invite a Friend
                  </IonButton>
                </IonMenuToggle>
              </div>
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={signout}
                  >
                    Signout
                  </IonButton>
                </IonMenuToggle>
              </div>
            </div>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonRouterOutlet id="main"></IonRouterOutlet>
    </>
  );
};