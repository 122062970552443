import styles from './index.module.scss';
import React from 'react';
import { AuthPage } from '../../../../components';
import { Typography } from '@mui/material';
import { IonLabel } from '@ionic/react';
import { style } from './../../../../Utility';
import { SideMenu } from "App/ITCTeacher/Components";

export const StudentProgress = () => {
  return (
    <>
    {/* <SideMenu/> */}
    
    <AuthPage title='Student Progress' showBackButton>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='ion-padding' style={{ width: style.maxWidth }}>
          <p className='color-tertiary heading m0'>HASHINI SHIVAGURU</p>
          <p className='subHeading'>FORM 3</p>
          <div className={styles.simpleTable}>
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Lesson</th>
                  <th>Date</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>ENGLISH</td>
                  <td>26/03/2022</td>
                  <td>
                    <IonLabel color='success'>85%</IonLabel>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>MATHS</td>
                  <td>25/03/2022</td>
                  <td>
                    <IonLabel color='success'>75%</IonLabel>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>SEJARAH</td>
                  <td>24/03/2022</td>
                  <td>
                    <IonLabel color='danger'>35%</IonLabel>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
