import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonTabs, IonTabBar, IonTabButton, IonIcon } from "@ionic/react";
import React, {useState} from "react";
import { SideMenu, BottomTabs } from "./Components";
import { TeacherAppRoutes } from "./Routes";
import { Redirect, Route, Switch } from "react-router";
import { person, notifications, home } from "ionicons/icons";
import { IonReactRouter } from "@ionic/react-router";

export const ITCTeacher = () => {
  return (
    <>
      <SideMenu />
      <IonRouterOutlet>
        {TeacherAppRoutes.map((route, index) => (
          <Route
            key={index + "teacher"}
            exact={route.exact}
            path={route.path}
            component={route.Component}
          />
        ))}
      </IonRouterOutlet>
    </>
  );
};
