import { useEffect } from 'react';
import { submitPayment, submitSlip } from 'api/api';
import { useHistory } from "react-router";


export const Success = () => {
    const history = useHistory();

    useEffect(() => {
        const selectedItems = JSON.parse(localStorage.getItem("selectedItems"));
        delete (selectedItems.totalAmount)
        selectedItems.subject_ids = selectedItems.subject_ids.join(",")
        submitPayment(selectedItems).then(res => {
            console.log(res)
            submitSlip({ payment_mode: 1, payment_id: res.data.data.id, payment_slip: "" }).then(res => {
                localStorage.removeItem("selectedItems");
                history.push("/s/join-class")
            })
        })
    }, [])
    return <></>;
};
