import { commonConstants } from "../constants";

const initialState = {
  loggedUserType: "teacher",
};

interface ReduxActionTypeProps {
  type: string;
  userType: string;
  payload: any;
}

export const common = (state = initialState, action: ReduxActionTypeProps) => {
  console.log("action", action);
  switch (action.type) {
    case commonConstants.USER_LOGGED_TYPE:
      return {
        ...state,
        loggedUserType: action.payload.userType,
      };
    default:
      return {
        ...state,
      };
  }
};
