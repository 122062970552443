import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { AuthPage, ClassDetails, StatusCard } from '../../../../components';
import { GET } from 'api/request';
import { SideMenu } from "App/ITCTeacher/Components";
import * as dayjs from "dayjs"
import * as weekday from "dayjs/plugin/weekday"
import { useHistory } from "react-router";
import CircularProgress from '@mui/material/CircularProgress';
dayjs.extend(weekday)

export const UpcomingLessons = () => {
  const history = useHistory();

  const handelClick = (value:any) => {
    window.location.href=value
  }
  let [loader, setLoader] = useState(true);
  let [classDetail, setClassDetail] : any = useState([])
  var week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(()=> {
    GET("teacher/classes/upcoming")
    .then((response:any)=> {
      const {data, status} = response
      if (status) {
        setClassDetail(data.data.data);
      }
      setLoader(false)
    })
    .catch((error:any) => {
      setLoader(false);
      setClassDetail([]);
      console.log(error)
    })
  },[])
  console.log(classDetail, "detailllllllllll");
  if(loader)
    return <CircularProgress />
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title='Upcoming Lessons' containerWidth='xs' showBackButton>
      <div className='ion-padding'>
        <p className='color-tertiary heading'>{week_days[dayjs().weekday()]}{dayjs().format(" (DD/MM/YYYY)")}</p>
        {classDetail.length ? classDetail.map((value:any,key:any)=> {
          return <Stack spacing={3}>
            <div>
              <StatusCard
                title='UPCOMING LESSON'
                color='danger'
                status={value.subject_name}
                description={
                  <>
                    <p className='textstyle'>STANDARD {value.standard_name}</p>
                    <p className='textstyle color-primary'>{value.actual_start_time} - {value.actual_end_time}</p>
                  </>
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <IonButton onClick={()=>handelClick(value.link)} shape='round' color='secondary'>
                LINK
              </IonButton>
              <IonButton shape='round' color='primary'>
                NOTES
              </IonButton>
            </div>
          </Stack>
        }): "No upcoming classes"}
      </div>
    </AuthPage>
    </>
  );
};
