import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route } from 'react-router';
import { StudentHome } from './Pages';
import { StudentAppRoutes } from './Routes';
import { SideMenu } from './Components';

export const ITCStudent = () => {
  return (
    <>
      <SideMenu />
      <IonRouterOutlet>
        {StudentAppRoutes.map((route, index) => <Route key={index + "student"} exact={route.exact} path={route.path} component={route.Component} />)}
      </IonRouterOutlet>
      <StudentHome />
    </>
  );
}
