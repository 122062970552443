import './contact.scss';
import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './contact.scss';
import { AuthPage } from '../../../../components';
import { contactUs } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';

// import { SideMenu } from "App/ITCTeacher/Components";

export const ContactUs = () => {
  const [loader, setLoader] = useState(true);
  const [contact_data, setContactData]: any = useState({});

  useEffect(()=>{
    contactUs().then(res => {
      const {data, status} = res;
      if(status) {
      console.log('res :>> ', data.data);

        setContactData(data.data)
      }
      setLoader(false)

    })
  }, [])
  if(loader)
  return <CircularProgress />
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title='Contact Us' showBackButton>
      <div className='fullScreen ion-justify-content-center flex column'>
        <Stack spacing={3}>
          <div className='contactus' style={{marginTop:"9rem"}}>
            <Typography className='heading'>
              {contact_data.address_title}
            </Typography>
            <Typography>{contact_data.address}</Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
           <a href={contact_data.whatsapp_link}>
             <IonButton shape='round' color='primary' className='contactbtn'>
              Whatsapp Us
            </IonButton>
            </a>
          </div>
        </Stack>
      </div>
    </AuthPage>
    </>
  );
};
