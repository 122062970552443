import styles from "./index.module.scss";
import React ,{useState,useEffect} from "react";
import { AuthPage } from "../../../../components";
import { TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IonBadge, IonCol, IonRow } from "@ionic/react";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from "App/ITCStudent/Components";
import { getStudentAtt } from "api/api";

export const Attendance = () => {
  
  const [startDate, setstartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());
  
  const [studentattendaceData, setstudentAttendanceData] = useState([]);
  
  useEffect(()=>{
    getStudentAtt().then(res => {
      setstudentAttendanceData(res.data.data);
    })
  },[])
  console.log(studentattendaceData,"my attendance data for student")
  
  return (
    <>
    <SideMenu/>
    <AuthPage title="Attendance" showBackButton>
      <IonRow className={`${styles.gap} ion-justify-content-center`}>
        <IonCol size="6" className="ion-text-right">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setstartDate(newValue);
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </IonCol>
        <IonCol size="6">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={endDate}
              onChange={(newValue: Date | null) => {
                setEndDate(newValue);
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </IonCol>
      </IonRow>
      <div
        className={`${styles.tableScroll} ${styles.simpleTable} ion-padding`}
      >
        <table style={{margin:"0 auto"}}>
          <thead>
            <tr>
              <th>No.</th>
              <th className={styles.cellWidth}>Classes</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>1</td>
              <td>MATHS (B.MELAYU) STANDARD 5</td>
              <td>22/03/2022</td>
              <td>8pm - 9pm</td>
              <td>
                <IonBadge className="badge" color="success">
                  Attended
                </IonBadge>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>MATHS (B.MELAYU) STANDARD 5</td>
              <td>22/03/2022</td>
              <td>8pm - 9pm</td>
              <td>
                <IonBadge className="badge" color="primary">
                  Absent
                </IonBadge>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>MATHS (B.MELAYU) STANDARD 5</td>
              <td>22/03/2022</td>
              <td>8pm - 9pm</td>
              <td>
                <IonBadge className="badge" color="success">
                  Attended
                </IonBadge>
              </td>
            </tr> */}
                        {
               studentattendaceData.length > 0 && studentattendaceData.map((item,index) =>(
                <>
              <tr key={index}>
              <td>{item.id}</td>
              <td>{item.standard_name}</td>
              <td>{item.date}</td>
              <td>{item.actual_start_time} - {item.actual_end_time}</td>
              <td>
              {
                 item.status == 1 ? (              <>
                  <IonBadge className="badge" color="success">
                    Attended
                  </IonBadge>
                </>) : (              <>
                <IonBadge className="badge" color="primary">
                  Absent
                </IonBadge>
              </>)
              }
              </td>

            </tr>
                </>
               ))
            }
          </tbody>
        </table>
      </div>
    </AuthPage>
    </>
  );
};
