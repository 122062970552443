import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonRouterOutlet,
} from "@ionic/react";
import React,{useState, useEffect} from "react";
import { GET } from "../api/request";
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect, Route } from "react-router";
import { person, notifications, home } from "ionicons/icons";
import { SideMenu } from "./ITCStudent/Components";
import { SideMenu as TSideMenu} from "App/ITCTeacher/Components";

import {
  Profile as TProfile,
  TeachersProfile,
  Notification as TNotification,
} from "./ITCTeacher/Pages";

import {
  Profile as SProfile,
  StudentProfile,
  Notification as SNotification,
} from "./ITCStudent/Pages";

const TabRootPage = () => {
  
  // const [userType, setUserType] = React.useState(
  //   localStorage.getItem("loggedUserType")
  // );

  let loggedUserType = localStorage.getItem("loggedUserType")

  console.log("__ tabs reached", loggedUserType);
  
  return (
    <>
      
      {/* {
         loggedUserType.toLowerCase() == "teacher" ? (<TSideMenu/>) : (<SideMenu/>)
      } */}

      {/* <SideMenu/> */}

      <IonTabs>
        <IonRouterOutlet>
          <Redirect exact path="/tabs" to="/tabs/profile" />
          <Route
            path="/tabs/profile"
            component={loggedUserType.toLowerCase() == "teacher" ? TProfile : SProfile}
            exact={true}
          />
          <Route
            path="/tabs/notifications"
            component={
              loggedUserType.toLowerCase() == "teacher" ? TNotification : SNotification
            }
            exact={true}
          />
          <Route
            path="/tabs/home"
            component={
              loggedUserType.toLowerCase() == "teacher" ? TeachersProfile : StudentProfile
            }
            exact={true}
          />
        </IonRouterOutlet>
        {/*-- Tab bar --*/}
        <IonTabBar slot="bottom">
          <IonTabButton tab="notifications" href="/tabs/notifications">
            <IonIcon icon={notifications} />
          </IonTabButton>
          <IonTabButton tab="home" href="/tabs/home">
            <IonIcon icon={home} />
          </IonTabButton>
          <IonTabButton tab="profile" href="/tabs/profile">
            <IonIcon icon={person} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default TabRootPage;
