import "./index.scss";
import React, {useState, useEffect} from "react";
import { useHistory } from "react-router";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonRouterOutlet,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { Stack, Typography } from "@mui/material";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

export const SideMenu = () => {
  
  const history = useHistory();
  
  const signout = () => {
    localStorage.removeItem("itc_token");
    localStorage.removeItem("profile");
    localStorage.removeItem("loggedUserType");
    localStorage.removeItem("persist:root");
    history.push("/login")
  }

  const [teacherProfile, setTeacherProfile]: any = useState({});
  const [loader, setLoader] = useState(false);
  
  let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  let loginwith = localStorage.getItem("loginwith") 
  let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  // useEffect(() => {
  //   GET("teacher/profile").then((response: any) => {
  //     const {data, status} = response
  //     if (status) {
  //       setTeacherProfile(data.data.data);
  //     }
  //     setLoader(false)
  //   });
  // }, []);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const [userType, setUserType] = React.useState(
    localStorage.getItem("loggedUserType")
  );
  console.log(userType,"userrrrrrrrrrrrrr")
  const { loggedUserType } = useSelector(
    (state: RootStateOrAny) => state.common
  );

  const onMenuCLick = async (path: string) => {
    history.push(path);
    setIsVisible(false);
  };

  // React.useEffect(() => {
  //   console.log("loggedUserType changed", loggedUserType);
  //   if (loggedUserType) {
  //     setUserType(loggedUserType);
  //   }
  // }, [loggedUserType, userType]);
  
  if(loader)
    return <CircularProgress />
  
  if(userType == "teacher" || userType == "Teacher") {
    return (
      <IonMenu contentId="main">
        <IonHeader>
          <IonToolbar>
            <div className="manu-header">
              <IonTitle>
                {profileData.name}
                <Typography>{(loginwith==='email')?profileData.email: profileData.mobile_no}</Typography>
              </IonTitle>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent class="outer-content">
          <IonList className="button-list ion-padding">
            <div className="classMenu">
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="secondary"
                    shape="round"
                    expand="block"
                    className="menuBtns"
                    onClick={() => onMenuCLick("/t/attendance")}
                  >
                    Attendance
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="secondary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/t/salary")}
                  >
                    Earning
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/tabs/profile")}
                  >
                    Profile
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
              <IonItem
                  className="menuItem"
                  color="primary"
                  style={{
                    marginTop: "2px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    borderBottomLeftRadius: isVisible ? "" : "25px",
                    borderBottomRightRadius: isVisible ? "" : "25px",
                    borderBottom: isVisible ? "1px solid white" : "",
                  }}
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <p className=" fullWidth"
                    style={{
                      textAlign: "center",
                      width: '100%',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    }}

                  >
                    Class{" "}
                    <IonIcon
                      style={{ verticalAlign: "middle", float: "right" }}
                      icon={isVisible ? caretUpOutline : caretDownOutline}
                    />
                  </p>
                </IonItem>
                {isVisible && (
                  <IonMenuToggle style={{ display: "contents" }}>
                    <IonItem
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/t/start-class")}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Enter Class
                      </p>
                    </IonItem>
                    {/* 
                    <IonItem
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/recording")}
                   >
                    {" "}
                    <p
                      style={{
                        margin: 0,
                        fontWeight: "500",
                        fontSize: 15,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Recording
                    </p>
                  </IonItem> */}
                  
                    <IonItem
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/t/upcoming-lessons")}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          textAlign: "center",
                          width: "100%",
                          margin: 0,
                        }}
                      >
                        Upcoming Lessons
                      </p>
                    </IonItem>
                    <IonItem
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/t/notes")}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          textAlign: "center",
                          width: "100%",
                          margin: 0,
                        }}
                      >
                        Notes
                      </p>
                    </IonItem>
                    <IonItem
                      color="primary"
                      style={{
                        borderBottomLeftRadius: "30px",
                        borderBottomRightRadius: "30px",
                      }}
                      onClick={() => onMenuCLick("/t/time-table")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          margin: 0,
                          width: "100%",
                        }}
                      >
                        Timetable
                      </p>
                    </IonItem>
                  </IonMenuToggle>
                )}
              </div>
              {/* <div>
              <IonMenuToggle>
                <IonButton
                  color="primary"
                  shape="round"
                  expand="block"
                  onClick={() => onMenuCLick("/student-progress")}
                >
                  Student Progress
                </IonButton>
              </IonMenuToggle>
            </div> */}

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/t/upcoming-programs")}
                  >
                    Upcoming Programs
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/t/announcement")}
                  >
                    Announcements
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/t/contact-us")}
                  >
                    Contact US
                  </IonButton>
                </IonMenuToggle>
              </div>

              
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/t/feedback")}
                  >
                    Feedback
                  </IonButton>
                </IonMenuToggle>
              </div>

              {/* <div>
                <IonMenuToggle>
                  <IonButton
                    color='primary'
                    shape='round'
                    expand='block'
                    onClick={() => onMenuCLick('/feedback')}
                  >
                    Feedback
                  </IonButton>
                </IonMenuToggle>
              </div> */}
            </div>
            <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={signout}
                  >
                    Signout
                  </IonButton>
                </IonMenuToggle>
              </div>
          </IonList>
        </IonContent>
      </IonMenu>
    )
  } else {
    return (
      <IonMenu side="start" contentId="main">
        <IonHeader>
          <IonToolbar>
            <div className="manu-header">
              <IonTitle>{profileData.name}</IonTitle>
              <Typography>{profileData.mobile_no}</Typography>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent class="outer-content">
          <IonList className="button-list">
            <div className="classMenu">
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="secondary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/enroll-subject")}
                  >
                    Enroll Subjects
                  </IonButton>
                </IonMenuToggle>
              </div>

              {/* <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/invoice")}
                  >
                    Invoice
                  </IonButton>
                </IonMenuToggle>
              </div> */}

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="secondary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/payment-history")}
                  >
                    Payment
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/profile")}
                  >
                    <span className="ion-text-left fullWidth">Profile</span>
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonItem
                  className="menuItem"
                  color="primary"
                  style={{
                    marginTop: "2px",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    borderBottomLeftRadius: isVisible ? "" : "30px",
                    borderBottomRightRadius: isVisible ? "" : "30px",
                  }}
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      textAlign: "center",
                      width: "100%",
                      margin: 0,
                      marginTop: 0,
                    }}
                  >
                    Classes{" "}
                    <IonIcon
                      style={{ verticalAlign: "middle", float: "right" }}
                      icon={isVisible ? caretUpOutline : caretDownOutline}
                    />
                  </p>
                </IonItem>
                {isVisible && (
                  <IonMenuToggle style={{ display: "contents" }}>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/join-class")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Join Class
                      </p>
                    </IonItem>
                    {/* <IonItem
                className="innerMenu"
                color="primary"
                style={{ borderBottom: "1px solid white" }}
                onClick={() => onMenuCLick("/s/recording")}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    textAlign: "center",
                    width: "100%",
                    marginTop: "0",
                    margin: 0,
                  }}
                >
                  Recording
                </p>
              </IonItem> */}
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/attendance")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Attendance
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{ borderBottom: "1px solid white" }}
                      onClick={() => onMenuCLick("/s/notes")}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0",
                          margin: 0,
                        }}
                      >
                        Notes
                      </p>
                    </IonItem>
                    <IonItem
                      className="innerMenu"
                      color="primary"
                      style={{
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                      }}
                      onClick={() => onMenuCLick("/s/time-table")}
                    >
                      <p
                        style={{
                          marginTop: "0",
                          margin: 0,
                          fontWeight: "500",
                          fontSize: 15,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Timetable
                      </p>
                    </IonItem>
                  </IonMenuToggle>
                )}
              </div>
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/student-progress")}
                  >
                    Student Progress
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/feedback")}
                  >
                    Feedback
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/announcement")}
                  >
                    Announcements
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/upcoming-programs")}
                  >
                    Upcoming Programs
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/contact-us")}
                  >
                    Contact US
                  </IonButton>
                </IonMenuToggle>
              </div>

              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={() => onMenuCLick("/s/invite-friend")}
                  >
                    Invite a Friend
                  </IonButton>
                </IonMenuToggle>
              </div>
              <div>
                <IonMenuToggle>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    onClick={signout}
                  >
                    Signout
                  </IonButton>
                </IonMenuToggle>
              </div>
            </div>
          </IonList>
        </IonContent>
      </IonMenu>
    )
  }  
};