import "./index.scss";
import React, { useEffect, useState } from "react";
import { AuthPage, Awesome3DButton } from "./../../../../components";
import { Stack, Typography } from "@mui/material";
import { useHistory } from "react-router";
import { style } from "./../../../../Utility";
import { getPackage } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from "App/ITCStudent/Components";

export const EnrollSubjects = () => {

  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [packageList, setPackageList] = useState([])
  
  useEffect(()=>{
    getPackage().then(res => {
      const {data, status} = res;
      if(status) {
        console.log('res :>> ', data.data);

        setPackageList(data.data)
      }
      setLoader(false)
    })
  }, [])
  
  console.log(packageList,"packageeeeeeeee");
  
  if(loader)
    return <CircularProgress />

  return (
    <>
          <SideMenu/>
          <AuthPage title="Enroll Subjects" showBackButton containerWidth="sm">
            <div className="ion-padding">
              <Stack spacing={5} sx={{ display: "flex", justifyContent: "center" }}>
                {packageList.map((classPackages) => (
                  <div>
                    
                    <Awesome3DButton onClick={() => history.push({ pathname:`/s/combo-package/${classPackages.id}`,
                      
                      state:{combopath:classPackages.id}}
                    
                    )}>
                      {classPackages.name}
                    </Awesome3DButton>

                    {/* 
                      history.push({
                      pathname: `/s/enroll-subject1/${classCategoryPackages.id}`,
                      state:{single:classCategoryPackages.single_subject_amount,
                        multiple:classCategoryPackages.multi_subject_amount}
                      }); 
                    */}

                    {classPackages.is_free_class ?
                      <p className="btnText">
                        Complementary <b>FREE CLASSES</b> are included
                      </p>
                      : ""
                    }
                  </div>
                ))}
              </Stack>
            </div>
          </AuthPage>
    </>
  );
};
