import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import './styles.scss';
import { arrowBackOutline } from 'ionicons/icons';
import { Teacher } from './Teacher';
import { OtherDetails } from './OtherDetails';
import watermark from './../../assets/watermark.svg';
import { useLocation } from "react-router-dom";
import { signup } from '../../api/auth';
import { protectedRoute } from '../../Utility';


export const Registeration = () => {
  
  const history = useHistory();
  const location = useLocation<any>();

  useEffect(() => {
    protectedRoute(false)

  }, [])

  let [data, setData] = useState<any>({
    ...location.state.data,
    'full_name' : "",
    'ic_no' : "",
    'gender' : "",
    'email' : "",
    'short_address' : "",
    'full_address' : "",
    'pincode' : "",
    'state_id' : "",
    'city_id' : "",
    'occupation' : "",
    'company_name' : "",
    'education_id' : "",
    'designation' : "",
    'institute' : "",
    'preffered_subject' : ""
  })
  const [steps, setSteps] = useState(0);
  

  const setButtonFn = async (values: any) => {

    setData({...data, ...values})
    if (steps === 0) {
      setSteps(1);
    }else {
      let register_data = {
        ...data,
        ...values
      }
    const signup_response = await signup(register_data)
      if(signup_response) {
        history.push('/')
      }
    };
  };


  return (
    <IonPage>
      <IonHeader class='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonIcon
              style={{
                verticalAlign: 'middle',
                fontSize: '2rem',
                //   float: 'left',
              }}
              icon={arrowBackOutline}
              onClick={() => history.goBack()}
            />
          </IonButtons>
          <IonTitle class='ion-text-center ion-text-uppercase'>
            Teacher's Profile
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='studenInfo ion-padding-horizontal'>
        <img src={watermark} className='hideOnWeb' />
        { steps === 0 ? <Teacher  handleNext={setButtonFn}  teacher_data={data}/> : <OtherDetails  setSteps={setSteps}  handleNext={setButtonFn}/>}
        {/* <div className='btnwrap'>
          <IonButton
            color='secondary'
            expand='block'
            shape='round'
            size='large'
            // onClick={() => setButtonFn()}
            onClick = {handleNext}
          >
            <FormattedMessage id='signup.next_button' />
          </IonButton>
          {steps > 0 && (
            <IonButton
              color='secondary'
              expand='block'
              shape='round'
              size='large'
              fill='outline'
              onClick={() => setSteps(0)}
            >
              Back
            </IonButton>
          )}
        </div> */}
      </IonContent>
    </IonPage>
  );
};
