import './index.scss';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { AuthPage } from '../../../../components';
import { IonButton } from '@ionic/react';
import './index.scss';
// import { SideMenu } from "App/ITCTeacher/Components";

export const Payment = () => {
  return (
    <>
    
    {/* <SideMenu/> */}
    <AuthPage title='Payment' showBackButton containerWidth='sm'>
      <div className='ion-padding payment-page'>
        <Typography className='color-primary'>HASHINI SHIVAGURU</Typography>
        <Typography className='formName'>FORM 3</Typography>
        <div>
          <div>
            <Box className={`ion-padding status-root-warning`}>
              <Box>
                <Typography
                  sx={{ color: 'black' }}
                  className={`status-heading background-warning`}
                >
                  PAYMENT DETAILS
                </Typography>
                <div className='ion-padding payment-calc'>
                  <p className='payment'>
                    Original: <span>RM 130.00</span>
                  </p>
                  <p className='payment'>
                    Discounted: <span>RM 0.00</span>
                  </p>
                  <div className='total-pay'></div>
                </div>
                <Typography className='total'>Total: RM 130.00</Typography>
              </Box>
            </Box>
          </div>
        </div>

        <div className='online'>
          <IonButton color='secondary' shape='round'>
            Online Payment
          </IonButton>
          <Typography className={`onlineText`}>
            Payment can be made via FPX and payment status will be updated
            immediately
          </Typography>
        </div>

        <div className='bankdetails'>
          <Typography className={`bankName`}>Bank Account Details</Typography>
          <Typography className='color-tertiary training'>
            ITC INTELLECT TRAINING
          </Typography>
          <Typography className='color-tertiary bankId'>
            546454354345 - MYBANK
          </Typography>
        </div>

        <div className='btnWrap'>
          <IonButton color='primary' shape='round'>
            Upload Payment Slip
          </IonButton>

          <Typography>
            Payment status will be updated within 48 hours after validation
            process
          </Typography>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
