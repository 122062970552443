/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/global.scss";

import React,{useState} from "react";
import { AppRoutes } from "./App.Routes";
import { Route, Switch } from "react-router-dom";
import {
  getPlatforms,
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { IntlProvider } from "react-intl";
import { en } from "./Language";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SplashScreen } from "@capacitor/splash-screen";
import { setStyle } from "./Utility";
import appStyles from "./theme/app.json";
import webStyles from "./theme/web.json";
import { SideMenu, BottomTabs } from "./App/ITCTeacher/Components";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { StudentAppRoutes } from "./App/ITCStudent/Routes";

import { SideMenu as StudentSideMenu } from "./App/ITCStudent/Components/SideBar";

import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

setupIonicReact();

const App: React.FC = () => {
  let profileRole = localStorage.getItem("loggedUserType") ? localStorage.getItem("loggedUserType") : "";
  let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  console.log(profileData,"my dataaaaaaaaaaaaa")
  SplashScreen.show({
    showDuration: 4000,
    autoHide: true,
  });
  console.log(profileRole,"roliiiiiiiii")
  const [platform] = React.useState(getPlatforms());
  const [userType, setUserType] = React.useState(
    // localStorage.getItem("loggedUserType")
    //   ? localStorage.getItem("profile")
    //   : "teacher"
    profileRole == "Teacher" ? "Teacher" : "Student" 
  );
  console.log(userType,"userrrrrrrr")
  // const { loggedUserType } = useSelector(
  //   (state: RootStateOrAny) => state.common
  // );

  React.useEffect(() => {
    if (platform.includes("android")) setStyle(appStyles);
    else setStyle(webStyles);
  }, [platform]);

  React.useEffect(() => {
    SplashScreen.hide()
      .then(() => {
        console.log("Splash completed");
      })
      .catch((err) => console.error("Splash hide error: ", err));
  });

  return (
    <IonApp>
      <ToastContainer />
      <Provider store={store}>
        <IntlProvider locale="en" messages={en}>
          <IonReactRouter>
            {profileData.role == "teacher" || profileData.role == "Teacher" ?
              <SideMenu />
              : <StudentSideMenu />
              // :<h1>Student huu</h1>
            }
              <IonRouterOutlet id="main">
                <Switch>
                  {AppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      component={route.Component}
                    />
                  ))}
                  {StudentAppRoutes.map((route) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      component={route.Component}
                    />
                  ))}
                </Switch>
              </IonRouterOutlet>
          </IonReactRouter>
        </IntlProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
