import { Typography } from '@mui/material';
import React from 'react';
import { AuthPage, LanguageBox } from '../../../../components';
import { LanguageOptions } from '../../../../components/LanguageBox';
import { style } from './../../../../Utility';
// import { SideMenu } from "App/ITCTeacher/Components";

const languages: Array<LanguageOptions[]> = [
    [
        {
            name: "BHASA MELAYU",
            time: "7PM - 8PM",
            day: "WEDNESDAY",
            checked: false
        }
    ],
    [
        {
            name: "ENGLISH",
            time: "8PM - 9PM",
            day: "TUESDAY",
            checked: false
        }
    ],
    [
        {
            name: "MATHEMATICS",
            time: "6PM - 7PM",
            day: "TUESDAY",
            checked: false
        }
    ],
    [
        {
            name: "SCIENCE(ENGLISH)",
            time: "6PM - 7PM",
            day: "TUESDAY",
            checked: false
        },
        {
            name: "SAINS(B.MELAYU)",
            time: "6PM - 7PM",
            day: "TUESDAY",
            checked: false
        }
    ],
    [
        {
            name: "SEJARAH",
            time: "6PM - 7PM",
            day: "THURSDAY",
            checked: false
        }
    ],
    [
        {
            name: "GEOGRAFI",
            time: "6PM - 7PM",
            day: "THURSDAY",
            checked: false
        }
    ],
    [
        {
            name: "BHASA TAMIL",
            time: "6PM - 7PM",
            day: "MONDAY",
            checked: false
        }
    ]
];

export const IndividualPackage = () => {
    return (
        <>
        {/* <SideMenu/> */}
        <AuthPage title="Individual Package" showBackButton>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: style.maxWidth }}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '1rem', paddingTop: '2rem' }}>Please Select your preferred subjects</Typography>
                    {languages.map(language => <LanguageBox title={language.length > 1 ? "SCIENCE" : undefined} languages={language} />)}
                </div>
            </div>
        </AuthPage>
        </>
    )
}
