import { IonIcon } from '@ionic/react';
import { Box, Typography } from '@mui/material';
import { arrowBack, arrowForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { AuthPage } from '../../../../components';
import { GET } from 'api/request';
import * as dayjs from "dayjs"
import * as weekday from "dayjs/plugin/weekday"
import { useHistory } from "react-router";
import { getUpcomingProgramForStudents } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { Swiper, SwiperSlide } from "swiper/react";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
dayjs.extend(weekday)
// import CircularProgress from '@mui/material/CircularProgress';

export const UpcomingPrograms = () => {
  const history = useHistory();

  const handelClick = (value:any) => {
    window.location.href=value
  }
  let [loader, setLoader] = useState(true);
  let [classDetail, setClassDetail] : any = useState([])
  var week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [upcomingProgramsData, setUpcomingProgramsData] = useState([]);
  
  useEffect(()=> {
    getUpcomingProgramForStudents().then(res => {
      setUpcomingProgramsData(res.data.data);
      setLoader(false);
    })
  },[])

  console.log("dsjkvbsdhvuisvui" + JSON.stringify(upcomingProgramsData))

  const [current,setCurrent] = useState<number>(0);
  const length = upcomingProgramsData.length;
  
  const nextSlide = () =>{
    setCurrent(current === length -1 ? 0 :current+1)
  }
  
  const prevSlide = () =>{
    setCurrent(current === 0 ? length-1 :current-1)
  }

  if(loader)
    return <CircularProgress />
  return (

    <>
    <SideMenu/>
    
    <AuthPage
      shareButton
      title='Upcoming Programs'
      showBackButton
      containerWidth='md'
    >
      <div className='ion-padding verticalSpace'>
        {/* <Box
          sx={{
            height: '15rem',
            marginTop: '-1px',
            padding: '1.5rem',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <IonIcon
              icon={arrowBack}
              style={{
                verticalAlign: 'middle',
                height: 'inherit',
                fontSize: 'x-large',
              }}
              onClick={prevSlide}
            />
            <Box
              sx={{
                height: '12rem',
                background: 'lightgray'
              }}
            >   
            
            {upcomingProgramsData.map((data, index) =>{  
              if(index === current){
                return <img key={index} src={data.image} style={{
                  height:'100%',
                  width:'100%'
                }}></img>
              }
            })}

            </Box>
            <IonIcon
              icon={arrowForward}
              style={{
                verticalAlign: 'middle',
                height: 'inherit',
                fontSize: 'x-large',
              }}
              onClick={nextSlide}
            />
          </div>
        </Box> */}

        <Swiper style={{height:"400px"}} navigation={true} modules={[Navigation]} className="mySwiper">
          {/* <SwiperSlide><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvxJY8zzNz0lnMMIBkUOmwYc1QexW18zIp5g&usqp=CAU" style={{width:'100%', height:"400px"}} /></SwiperSlide>
          <SwiperSlide><img src="https://cdn-icons-png.flaticon.com/512/1053/1053244.png?w=360" style={{width:'100%', height:"400px"}} /></SwiperSlide> */}
           {upcomingProgramsData.map((singleProgram)=>(
            <SwiperSlide><img src={singleProgram.image} style={{width:'100%'}} /></SwiperSlide>
          ))}
        </Swiper>

      </div>
    </AuthPage>
    </>
  );
};
