import styles from "./index.module.scss";
import React, { useEffect, useState } from "react";
import { AuthPage } from "./../../../../components";
import { TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IonBadge, IonButton } from "@ionic/react";
import { style } from "./../../../../Utility";
import { useHistory } from "react-router";
import { getRecording } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { recording } from "ionicons/icons";
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';

export const Recording = () => {
  
  const [value, setValue] = React.useState<Date | null>(new Date());
  
  const [loader, setLoader] = useState(true);
  
  const history = useHistory();
  
  const [recordingList, setRecordingList] = useState([])
  
  useEffect(()=>{
    getRecording().then(res => {
      const {data, status} = res;
      if(status) {
        console.log('res :>> ', data.data);

        setRecordingList(data.data)
      }
      setLoader(false)
    })
  }, [])
  
  const handleClick = (redirect) => {
    window.location.href = redirect;
  }
  console.log(recordingList,"this is a my recording list");
  
  if(loader)
    return <CircularProgress />

  return (
    <>
      <SideMenu/>
      

    <AuthPage title="Recording" showBackButton containerWidth="md">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        <div style={{ width: style.maxWidth }}>
          <div
            className="ion-padding recording"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ maxWidth: "48%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  value={value}
                  onChange={(newValue: Date | null) => {
                    setValue(newValue);
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ maxWidth: "48%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  value={value}
                  onChange={(newValue: Date | null) => {
                    setValue(newValue);
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div
            className="ion-padding simpleTable"
            style={{ paddingTop: "2rem" }}
          >
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th style={{width:"150px"}}>Class</th>
                  <th style={{width:"150px"}}>Date</th>
                  <th style={{width:"150px"}}>Link</th>
                </tr>
              </thead>
              <tbody>
                {recordingList.map((recording,index)=>(
                  <tr>
                    <td>{index+1}</td>
                    <td>{recording.subject}</td>
                    <td>{recording.date}</td>
                    <td>
                      <IonBadge
                        className="payBadge"
                        color="secondary"
                        onClick={() => handleClick(recording.file_name)}
                      >
                        Link
                      </IonBadge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
