import { IonButton, IonToolbar } from "@ionic/react";
import { Container, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { useState } from "react";
import { TextInput } from "../../App/ITCTeacher/Components/inputFields";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import * as yup from "yup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { validate } from "../../api/auth";
export const Teacher = (props: any) => {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 11px)",
      right: "calc(50% + 11px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#AB1F23",
        paddingTop: 4,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#AB1F23",
        paddingTop: 4,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#AB1F23",
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
      ...(ownerState.active && {
        color: "#784af4",
      }),
      "& .QontoStepIcon-completedIcon": {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18,
      },
      "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
      },
    })
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="completed-circle" />
        ) : (
          <div className="circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));
  const steps = ["Teacher's Information", "Other Information"];
  const teacherSchema = yup.object({
    full_name: yup.string().required(),
    ic_no: yup.string().required(),
    gender: yup.string().required(),
    email: yup.string().email().required()
    .test('Unique Email', 'This Email is already in use', // <- key, message
     async(value) => {
      return value && value.length > 5? validate({email:value}).then(res => {
        console.log('res', res)
        if(res.status) {
          return true
        } else {
          return false
        }
      }) : true
    }
    ).nullable(),
    short_address: yup.string().required(),
    full_address: yup.string().nullable(),
    pincode: yup.string().required().nullable(),
    state_id: yup.string().required().nullable(),
    city_id: yup.string().required().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      ...props.teacher_data
      
    },
    validationSchema: teacherSchema,
    onSubmit: (values) => {
      props.handleNext(values)
    },
  });

  return (
    <Container maxWidth="md">
      <IonToolbar className="stickyStepper">
        <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </IonToolbar>
      <h3 className="sectionHeading">Teacher's Information</h3>
      <form className="infoForm" onSubmit={formik.handleSubmit}>
        <TextInput
          formik={formik}
          label={"Full Name"}
          field_name={"full_name"}
        />
        <TextInput formik={formik} label={"IC No"} field_name={"ic_no"} />

        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
        row
        name="gender"
        onChange={formik.handleChange}
        value={formik.values.gender}
      >
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />

      </RadioGroup>
      <span style={{ color: "red" }}>{formik.errors.gender}</span>

    </FormControl>
        <TextInput formik={formik} label={"Email"} field_name={"email"} />
        <TextInput
          formik={formik}
          label={"Address Line 1"}
          field_name={"short_address"}
        />
        <TextInput
          formik={formik}
          label={"Address Line 2(optional)"}
          field_name={"full_address"}
        />
        <TextInput
          formik={formik}
          label={"Post Code"}
          field_name={"pincode"}
        />
        <TextInput formik={formik} label={" State"} field_name={"state_id"} />
        <TextInput formik={formik} label={" City"} field_name={"city_id"} />

        {/* <TextField
          label='Full Name'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('full_name', e.target.value)}
        /> */}

        {/* <TextField
          label='IC No'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('ic_no', e.target.value)}

        />

        <TextField
          label='Gender'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('gender', e.target.value)}
        /> */}

        {/* <TextField
          label='Email'
          type='email'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('email', e.target.value)}

        /> */}

        {/* <TextField
          label='Address Line 1'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('short_address', e.target.value)}

        /> */}

        {/* <TextField
          label='Address Line 2(optional)'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('full_address', e.target.value)}

        /> */}

        {/* <TextField
          label='Post Code'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('pincode', e.target.value)}

        /> */}

        {/* <TextField
          label='State'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('state_id', e.target.value)}

        /> */}

        {/* <TextField
          label='City'
          type='text'
          id='outlined-size-small'
          size='medium'
          fullWidth
          color='primary'
          onChange={(e) => props.handleName('city_id', e.target.value)}
        /> */}
        <div className="btnwrap">
          <IonButton
            color="secondary"
            expand="block"
            shape="round"
            size="large"
            type="submit"
            // onClick={() => setButtonFn()}
            // onClick={formik.handleSubmit}
          >
            <FormattedMessage id="signup.next_button" />
          </IonButton>
        </div>
      </form>
    </Container>
  );
};

export default Teacher;
