import React, { useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { AuthPage, StudentDashboardCard } from '../../../../components';
import { useHistory } from 'react-router';
import './styles.scss';
import {SignOut} from "../../Components/SignOut/index";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';


interface StudentHomeProps {}

export const StudentHome = (props: StudentHomeProps) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);

  // let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  // let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  // console.log(profileData.image,"profileeeeeeee")
  const [profileData, setProfileData] :any = useState({});
  useEffect(() => {
    GET("student/profile").then((response: any) => {
      const {data, status} = response
      if (status) {
        setProfileData(data.data.data);
      }
      setLoader(false)
    });
  }, []);
  console.log(profileData[0],"achaaaaaaaa");
  const signout = () => {
    localStorage.removeItem("itc_token");
    localStorage.removeItem("profile");
    localStorage.removeItem("loggedUserType");
    localStorage.removeItem("persist:root");
    history.push("/login")
  }
  if(loader)
    return <CircularProgress />
  return (
    <>
    
    <SideMenu/>
    <AuthPage
      title={<FormattedMessage id='student.profile_title' />}
      menu={false}
      containerWidth='xs'
    >
      <div className='ion-padding' style={{ background: 'none' }}>
        <StudentDashboardCard
          name={profileData.name}
          title={profileData.class? profileData.class : ""}
          image={profileData?.image}
          showView
          showCamera
          onView={() => history.push('/s/status')}
        />
        <SignOut page="/login" />
      </div>
    </AuthPage>
    </>
  );
};
