import axios from 'axios';




/**
 * @method Request
 * @param {object} configs
 *
 * @return {promise}
 */


 const addAuthHeader = (ctx) => {
	if (typeof localStorage == 'object' && localStorage.getItem('itc_token')) {

		return {
			Authorization: `Bearer ${localStorage.getItem('itc_token')}`,
			'Content-Type': 'application/json',
		};
	} 
};

const Request = (configs = {}) => {
	const baseUrl = process.env.REACT_APP_API_BASEURL
	
	const url = baseUrl + configs.path;
	const defaultHeaders = {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	};

	let headers = {};
	
		headers = { ...addAuthHeader(), ...defaultHeaders, ...configs.headers };


	configs = { ...configs, url, headers };
	

	return axios(configs);
};



export const GET = (path, configs = {}) => Request({ ...configs, path, method: 'GET' });
export const POST = (path, configs = {}, headers = {}) => Request({ method: 'POST', path, ...configs });
export const PATCH = (path, configs = {}) => Request({ ...configs, path, method: 'PATCH' });
export const PUT = (path, configs = {}) => Request({ ...configs, path, method: 'PUT' });
export const DELETE = (path, configs = {}) => Request({ ...configs, path, method: 'DELETE' });

export default Request;
