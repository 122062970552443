import './index.scss';
import { Box, Typography } from '@mui/material';
import { AuthPage } from '../../../../components';
import { IonButton } from '@ionic/react';
import './index.scss';
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { SideMenu } from 'App/ITCStudent/Components';
import { sha256 } from 'js-sha256';
import { useEffect, useState } from 'react';
import IPay88, { Pay } from "ipay88-sdk";
export const Payment = () => {
  const successNotify = data => {
    console.log("success")
  };

  const cancelNotify = data => {
    console.log("cancel")
  };

  const failedNotify = data => {
    console.log("fail")
  };

  const history = useHistory();
  const location = useLocation();
  let randomNumber = Math.ceil(Math.random() * 1000000 + 1)
  const [amount, setAmount] = useState(location.state);
  const profile = JSON.parse(localStorage.getItem("profile"))

  const totalAmount = location.state;
  console.log(sha256('BnnSe78EeKM012251234565100MYR'));
  useEffect(() => {
    localStorage.setItem("selectedItems", JSON.stringify(location.state));
  }, [])
  const callpay = async () => {
    try {
      const data: any = {
        "merchantCode": "M01225",
        "merchantKey": "BnnSe78EeK",
        "referenceNo": `A0000${randomNumber}`,
        "amount": "1.00",
        "currency": "MYR",
        "productDescription": "Course purchase",
        "userName": profile.name,
        "userEmail": profile.email,
        "userContact": profile.mobile_no,
        "remark": "",
        "lang": "UTF-8",
        "country": "MYR",
        "signatureType": "SHA256",
        "signature": sha256(`BnnSe78EeKM01225A0000${randomNumber}100MYR`),
        "responseURL": "https://itceduvirtual.com/payment/success",
        "backendUrl": "https://itceduvirtual.com/itc-web/public"
      };

      const errs = await Pay(data);
      console.log("error", errs);
      if (Object.keys(errs).length > 0) {
        console.log(errs);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (

    <>
      <SideMenu />
      <AuthPage title='Payment' showBackButton containerWidth='sm'>
        <div className='ion-padding payment-page'>
          <Typography className='color-primary'>HASHINI SHIVAGURU</Typography>
          <Typography className='formName'>FORM 3</Typography>
          <div>
            <div>
              <Box className={`ion-padding status-root-warning`}>
                <Box>
                  <Typography
                    sx={{ color: 'black' }}
                    className={`status-heading background-warning`}
                  >
                    PAYMENT DETAILS
                  </Typography>
                  <div className='ion-padding payment-calc'>
                    <div className='payment'>
                      <p>Original:</p>
                      <span>RM
                        {totalAmount['totalAmount']}
                      </span>
                    </div>
                    <div className='payment'>
                      <p>Discounted:</p>
                      <span>RM 0.00</span>
                    </div>
                    <div className='total-pay'></div>
                  </div>
                  <Typography className='total'>Total: RM {totalAmount['totalAmount'].toFixed(2)}</Typography>
                </Box>
              </Box>
            </div>
          </div>
          <form method="post" action="https://payment.ipay88.com.my/ePayment/entry.asp" name="ePayment">
            <div className='online'>
              <input type="hidden" name="MerchantCode" value="M01225" />
              <input type="hidden" name="RefNo" value={`A0000${randomNumber}`} />
              <input type="hidden" name="Amount" value="1.00" />
              <input type="hidden" name="Currency" value="MYR" />
              <input type="hidden" name="ProdDesc" value="Course Purchase" />
              <input type="hidden" name="UserName" value={profile.name} />
              <input type="hidden" name="UserEmail" value={profile.email} />
              <input type="hidden" name="UserContact" value={profile.mobile_no} />
              <input type="hidden" name="Remark" value="" />
              <input type="hidden" name="Lang" value="UTF-8" />
              <input type="hidden" name="SignatureType" value="SHA256" />
              <input type="hidden" name="Signature"
                value={sha256(`BnnSe78EeKM01225A0000${randomNumber}100MYR`)} />
              <input type="hidden" name="ResponseURL" value="https://itceduvirtual.com/payment/success" />
              <input type="hidden" name="BackendURL"
                value="https://itceduvirtual.com/itc-web/public" />

              <IonButton color='secondary' shape='round' type="submit">
                Online Payment
              </IonButton>
              <Typography className={`onlineText`}>
                Payment can be made via FPX and payment status will be updated
                immediately
              </Typography>
            </div>
          </form>





        </div>
      </AuthPage >
    </>
  );
};
