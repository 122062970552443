import { Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { AuthPage, Awesome3DButton } from "../../../../components";
import { style } from "./../../../../Utility";
// import { SideMenu } from "App/ITCTeacher/Components";

export const ComboPackage = () => {
  const history = useHistory();

  return (
  <>
    {/* <SideMenu/> */}
   <AuthPage title="Combo Package" showBackButton>
      <div style={{ paddingTop: "5rem" }} className="ion-padding">
        <Stack spacing={5} sx={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Awesome3DButton
              style={{
                width: style.Awesome3DButtonWidth,
                height: style.Awesome3DButtonHeight,
                fontSize: style.Awesome3DButtonTextSize,
              }}
              onClick={() => history.push("/t/individual-subjects")}
            >
              Combo Package (30 DAYS)
            </Awesome3DButton>
            <Typography sx={{ textAlign: "center" }}>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 130</b> for all subjects.
              </div>
              <div>
                Complementary <b>FREE CLASSES</b> are included
              </div>
            </Typography>
          </div>
          <div>
            <Awesome3DButton
              style={{
                width: style.Awesome3DButtonWidth,
                height: style.Awesome3DButtonHeight,
                fontSize: style.Awesome3DButtonTextSize,
              }}
              onClick={() => history.push("/t/individual-subjects")}
            >
              Combo Package (15 DAYS)
            </Awesome3DButton>
            <Typography sx={{ textAlign: "center" }}>
              <div>
                <b style={{ fontSize: "x-large" }}>RM 65</b> for all subjects.
              </div>
              <div>
                Complementary <b>FREE CLASSES</b> are included
              </div>
            </Typography>
          </div>
        </Stack>
      </div>
    </AuthPage>
    </>
  
  );
};
