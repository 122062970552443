import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { AuthPage } from '../../../../components';
import { Typography } from '@mui/material';
import { IonBadge } from '@ionic/react';
import { style } from './../../../../Utility';
import { getStudentProgress } from 'api/api';

// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';


export const StudentProgress = () => {
  const [studentProgress, setStudentProgress] = useState([]);
  const profile = JSON.parse(localStorage.getItem("profile"))

  useEffect(() => {
    getStudentProgress().then((res) => {
      setStudentProgress(res.data);
    })
  }, [])
  console.log(studentProgress, "progressssssssss")
  return (
    <>
      <SideMenu />
      <AuthPage title='Student Progress' showBackButton containerWidth='md'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='ion-padding'
            style={{ width: style.maxWidth, paddingTop: '' }}
          >
            <p className={`${styles.heading} color-tertiary heading`}>
              {profile.name}
            </p>
            <p className='subHeading'>{profile.student_details.standard_id}</p>
            <div className={styles.simpleTable}>
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Lesson</th>
                    <th>Date</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {studentProgress?.length && studentProgress.map((student, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{student.subject}</td>
                      <td>{student.date}</td>
                      <td>
                        <IonBadge className={parseInt(student.progress) > 75 ? 'badgeSuccess' : 'badgeFail'} color={parseInt(student.progress) > 75 ? 'success' : 'primary'}>
                          {student.progress}%
                        </IonBadge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AuthPage>
    </>
  );
};
