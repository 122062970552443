import { IonButton } from '@ionic/react';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthPage } from '../../../../components';
import './share.scss';
import { getInviteData } from '../../../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router";
import {CopyToClipboard} from 'react-copy-to-clipboard';

// import { SideMenu } from "App/ITCTeacher/Components";

import { SideMenu } from 'App/ITCStudent/Components';

export const InviteFriend = () => {
  let profile = localStorage.getItem("profile") ? localStorage.getItem("profile") : "";
  let [profileData, setProfileData] = useState(profile ? JSON.parse(profile) : {})
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [inviteData, setInviteData] = useState({
    "invite_text": "",
    "invite_link": ""
  })
  const [copied,setCopied] = useState(false);
  useEffect(()=>{
    getInviteData().then(res => {
      const {data, status} = res;
      if(status) {
        console.log('res :>> ', data.data);

        setInviteData(data.data)
      }
      setLoader(false)
    })
  }, [])
  console.log(inviteData,"packageeeeeeeee");
  if(loader)
    return <CircularProgress />
  return (

    <>
    
    <SideMenu/>
    <AuthPage title='Invite Friend' showBackButton containerWidth='md'>
      <div className='ion-padding fullScreen' style={{ paddingTop: '4rem' }}>
        <Stack spacing={2} className='center'>
          <div className='ion-text-center'>
            <CopyToClipboard text={inviteData.invite_link} onCopy={()=> setCopied(true)}>
              <IonButton className='shareBtn' color='secondary' shape='round'>
                Share Link
              </IonButton>
            </CopyToClipboard>
          </div>
          {copied ? <p className='shareText'><b>Copied</b></p> : null}
          <div>
            <p className='shareText'>
              {/* Invite a friend to get 10 % <b> discount </b>
              from their tution fees up to 3 months consecutively. */}
              {inviteData.invite_text}
            </p>
          </div>
          <div className='link ion-text-center'>
            <Link
              to={inviteData.invite_link}
              target={'_blank'}
            >
              {inviteData.invite_link}
            </Link>
          </div>
        </Stack>
      </div>
    </AuthPage>
    </>
  );
};
