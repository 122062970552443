// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getMessaging,getToken,onMessage} from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyALTAJlb9aWj54e23vga3mMOM30CCBOQcQ",
  authDomain: "itcfirebase-f8f3a.firebaseapp.com",
  projectId: "itcfirebase-f8f3a",
  storageBucket: "itcfirebase-f8f3a.appspot.com",
  messagingSenderId: "1087446524858",
  appId: "1:1087446524858:web:b1eea5e1dcbbf5f69c7383"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BEymCuSenekgcOvYPFV8D-JqgDiUaCI6ZuOtejuS0NdK-1W09dpZTJh54wob_Nw4Kww19di8NjjFSQMnHR5micg" })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  }); 