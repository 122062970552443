import styles from "./index.module.scss";
import React from "react";
import { AuthPage } from "../../../../components";
import { TextField, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IonBadge, IonButton, IonCol, IonRow } from "@ionic/react";
import { useHistory } from "react-router";
// import { SideMenu } from "App/ITCTeacher/Components";

export const Recording = () => {
  const [startDate, setstartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());
  const history = useHistory();
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title="Recording" showBackButton>
      <IonRow className={`${styles.gap} ion-justify-content-center`}>
        <IonCol size="6" className="ion-text-right">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setstartDate(newValue);
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </IonCol>
        <IonCol size="6">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={endDate}
              onChange={(newValue: Date | null) => {
                setEndDate(newValue);
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </IonCol>
      </IonRow>
      <div className={`${styles.tableScroll} ion-padding`}>
        <table className={styles.simpleTable}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Class</th>
              <th>Date</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>English</td>
              <td>22/03/2022</td>
              <td>
                <IonBadge
                  className="payBadge"
                  color="secondary"
                  onClick={() => history.push("/t/combo-package")}
                >
                  Link
                </IonBadge>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>MATHS (B.MELAYU) STANDARD 5</td>
              <td>22/03/2022</td>
              <td>
                <IonBadge
                  className="payBadge"
                  color="secondary"
                  onClick={() => history.push("/t/combo-package")}
                >
                  Link
                </IonBadge>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>MATHS (B.MELAYU) STANDARD 5</td>
              <td>22/03/2022</td>
              <td>
                <IonBadge
                  className="payBadge"
                  color="secondary"
                  onClick={() => history.push("/t/combo-package")}
                >
                  Link
                </IonBadge>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </AuthPage>
    </>
  );
};
