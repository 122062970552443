import React, {useState, useEffect} from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FormattedMessage } from "react-intl";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { useHistory } from "react-router";
import { Container } from "@mui/material";
import "./index.scss";
import watermark from "../../../../assets/watermark.svg";
import MemoizedFormattedMessage from "react-intl/src/components/message";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
import { SideMenu } from "App/ITCTeacher/Components";
import { Redirect, Route } from "react-router";
import { person, notifications, home } from "ionicons/icons";

export const TeachersProfile = () => {
  const history = useHistory();
  const [teacherProfile, setTeacherProfile]: any = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    GET("teacher/profile").then((response: any) => {
      const {data, status} = response
      if (status) {
        setTeacherProfile(data.data.data);
      }
      setLoader(false)
    });
  }, []);
  const signout = () => {
    localStorage.removeItem("itc_token");
    localStorage.removeItem("profile");
    localStorage.removeItem("loggedUserType");
    localStorage.removeItem("persist:root");
    history.push("/login")
  }
  console.log(teacherProfile, "teacherrrrrrrrrrrrrrrrrrrr");
  if(loader)
  return <CircularProgress />
  let loggedUserType = "teacher";
  console.log("__ tabs reached", loggedUserType);
  return (
    <>
    {/* <SideMenu/> */}
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle style={{textAlign:"center"}}>TEACHER'S PROFILE</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding curved">
        <img src={watermark} className="hideOnWeb" />
        <Container maxWidth="xs">
          <div className="teacher-dash">
            <StudentDashboardCard
              name={teacherProfile.name}
              title={teacherProfile.role}
              onView={() => window.location.href = "/t/welcome-educator"}
              // isActive
              showView
              isTeacher
              showCamera
              image={teacherProfile.image}
            />
            <div>
              <IonButton
                color="secondary"
                fill="solid"
                expand="full"
                shape="round"
                className="signout"
                onClick={() => signout()}
              >
                {" "}
                Sign out{" "}
              </IonButton>
            </div>
          </div>
        </Container>
      </IonContent>
    </IonPage>
    </>
  );
};
