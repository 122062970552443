import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthPage, Awesome3DButton } from "../../../../components";
import "./styles.scss";
import CircularProgress from '@mui/material/CircularProgress';
import { getPackageCategory } from '../../../../api/api';
// import { SideMenu } from "App/ITCTeacher/Components";
import { useLocation } from "react-router-dom";

import { SideMenu } from "App/ITCStudent/Components";

export const ComboPackage = (props):any => {
  
  const [loader, setLoader] = useState(true);

  const location = useLocation();
  
  const path = location.state;

  const history = useHistory();
  
  const [packageCategoryList, setPackageCategoryList] = useState([])
  
  useEffect(()=>{
    getPackageCategory(props.match.params.id).then(res => {
      const {data, status} = res;
      if(status) {
        setPackageCategoryList(data.data)
      }
      setLoader(false)
    })

  }, [])

  console.log(packageCategoryList,"packageeeeeeeee");
  
  if(loader)
    return <CircularProgress />
  return (
    <>
    
    <SideMenu/>
    <AuthPage title="Enroll Subjects" showBackButton containerWidth="sm">
      <div className="ion-padding">
        <Stack spacing={5} sx={{ display: "flex", justifyContent: "center" }}>
          {packageCategoryList.map((classCategoryPackages) => (
            <div>
              <Awesome3DButton onClick={() =>{
                 history.push({
                    pathname: `/s/enroll-subject1/${classCategoryPackages.id}`,state:{single:classCategoryPackages.single_subject_amount,
                      multiple:classCategoryPackages.multi_subject_amount,
                      package:path['combopath'],
                    category:classCategoryPackages.id}
                });
              }}>
                {classCategoryPackages.title}
              </Awesome3DButton>

              {classCategoryPackages.is_all_subjects == 1 ? 
                <p className="btnText">
                  <b style={{ fontSize: "x-large" }}>RM {classCategoryPackages.amount}</b> for all subjects.
                  Complementary <b>FREE CLASSES</b> are included
                </p>
                : 
                <Typography sx={{ textAlign: "center" }}>
                  <div>
                    <b style={{ fontSize: "x-large" }}>RM {classCategoryPackages.single_subject_amount}</b> / one subject.
                  </div>
                  <div>
                    <b style={{ fontSize: "x-large" }}>RM {classCategoryPackages.multi_subject_amount}</b> / one subject.
                  </div>
                  <div>Applies when enroll two or more subjects</div>
                </Typography>
              }
            </div>
          ))}
        </Stack>
      </div>
    </AuthPage>
    </>
  );
};
