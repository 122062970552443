import {
    IonMenuToggle,
    IonButton,
  } from "@ionic/react";
import { useHistory } from "react-router";
import { useState } from "react";

export const SidebarButton = ({color, shape, expand, changePage, text, style={}}) => {
    const history = useHistory();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const onMenuCLick = async (path: string) => {
        history.push(path);
        setIsVisible(false);
    };
    console.log(style)
    return (
        <IonMenuToggle>
            <IonButton
            color={color}
            shape={shape}
            expand={expand}
            onClick={() => onMenuCLick(changePage)}
            style={style}
            >
            {text}
            </IonButton>
        </IonMenuToggle>
    )
}