import { IonButton } from "@ionic/react";
import { useHistory } from "react-router";


export const SignOut = (props: any) => {
    const history = useHistory();
    const handleSignOut = (redirectPage) => {
        localStorage.removeItem("itc_token");
        localStorage.removeItem("profile");
        localStorage.removeItem("loggedUserType");
        localStorage.removeItem("persist:root");
        history.push(redirectPage)
    }
    return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "1rem",
          }}
        >
          <IonButton
            className="singutbtn"
            color="secondary"
            fill="solid"
            shape="round"
            onClick={() => handleSignOut(props.page)}
          >
            {" "}
            Sign out{" "}
          </IonButton>
        </div>
    )
}