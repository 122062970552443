import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { AuthPage } from '../../components';
import './styles.scss';
import { arrowBackOutline } from 'ionicons/icons';
import { styled } from '@mui/material/styles';
import * as utility from './../../Utility';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import * as yup from "yup";
import { protectedRoute, style } from "./../../Utility";
import { messages } from "../../Utility/messages";
import { useFormik } from "formik";
import { TextInput } from "../../App/ITCTeacher/Components/inputFields";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useLocation } from "react-router-dom";
import { validate } from "../../api/auth";
import { getClassList } from "../../api/api";
import CircularProgress from '@mui/material/CircularProgress';


export const StudentInfo = () => {
  const location = useLocation<any>();
  const [loader, setLoader] = useState(true);

  const [personName, setPersonName] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const history = useHistory();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '100%',
      },
    },
  };
  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const name_regex = /^[a-z A-Z]+$/i
  const mobile_regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/
  const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  useEffect(() => {
    protectedRoute(false)

  }, [])


  const SignupSchema = yup.object({
    full_name: yup.string().required(messages.required.full_name)
      .matches(name_regex, messages.regex.name),
    ic_no: yup.string().required(),
    gender: yup.string().required(),
    standard_id: yup.string().required(),
    school_name: yup.string().required(),
    email: yup.string().email().required(),
    referral_id: yup.string()
      .test('Unique referral', 'This Referral code doesn\'t exist ', // <- key, message
        async (value) => {
          return value && value.length >= 8 ? validate({ referral_code: value }).then(res => {
            console.log("helloooooooooo", res)
            if (res.status) {
              return true
            } else {
              return false
            }
          }) : true
        }),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      ic_no: "",
      gender: "",
      standard_id: "",
      school_name: "",
      email: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      values = { ...location.state.data, ...values }
      history.push({
        pathname: "./parents-info",
        state: { data: values },
      });
    },
  });

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 11px)',
      right: 'calc(50% + 11px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#AB1F23',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#AB1F23',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#AB1F23',
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className='completed-circle' />
        ) : (
          <div className='circle' />
        )}
      </QontoStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  const steps = ['Student Information', 'Parent Information'];
  const gender = ['Male', 'Female', 'Other'];

  const [standard, setStandard] = useState([]);
  useEffect(() => {
    getClassList().then(res => {
      const { data } = res;
      setStandard(data)
      setLoader(false)
    })
  }, [])
  console.log(standard, "standardddddddddd")
  if (loader)
    return <CircularProgress />
  return (
    <IonPage>
      <IonHeader class='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonIcon
              style={{
                verticalAlign: 'middle',
                fontSize: '2rem',
                //   float: 'left',
              }}
              icon={arrowBackOutline}
              onClick={() => history.goBack()}
            />
          </IonButtons>
          <IonTitle class='ion-text-center ion-text-uppercase'>
            Student Profile
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='studenInfo ion-padding-horizontal'>
        <Container maxWidth='md'>
          <IonToolbar className='stickyStepper'>
            <Stepper
              alternativeLabel
              activeStep={1}
              connector={<QontoConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </IonToolbar>
          <h3 className='sectionHeading'>Student Information</h3>
          <form className='infoForm' onSubmit={formik.handleSubmit}>
            <TextInput formik={formik} label={"Full Name"} field_name="full_name" />
            <TextInput formik={formik} label={"IC No"} field_name="ic_no" />
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
              <RadioGroup
                row
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
              >
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />

              </RadioGroup>
              <span style={{ color: "red" }}>{formik.errors.gender}</span>

            </FormControl>
            <FormControl>
              <InputLabel id='demo-multiple-name-label'>
                Standard Form
              </InputLabel>
              <Select
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                value={formik.values.standard_id}
                onChange={formik.handleChange}
                input={<OutlinedInput label='Name' />}
                MenuProps={MenuProps}
                name="standard_id"
              >
                {standard.map((singleStandard, i) => (
                  <MenuItem key={singleStandard.name} value={singleStandard.id}>
                    {singleStandard.name}
                  </MenuItem>
                ))}
              </Select>
              <span style={{ color: "red" }}>{formik.errors.standard_id}</span>
            </FormControl>
            <TextInput formik={formik} label={"School Name"} field_name="school_name" />
            <TextInput formik={formik} label={"E-mail"} field_name="email" />
            <TextInput formik={formik} label={"Referral Student ID [Optional]"} field_name="referral_id" />
            {/* 
              <IonButton
              color='secondary'
              expand='block'
              shape='round'
              size='large'
              // onClick={() => history.push('/parents-info')}
              // onClick={formik.handleSubmit}>
              <FormattedMessage id='signup.next_button' />
            </IonButton> */}
            <div className="btnwrap">
              <IonButton
                color="secondary"
                expand="block"
                shape="round"
                size="large"
                type="submit"
              // onClick={() => setButtonFn()}
              // onClick={formik.handleSubmit}
              >
                <FormattedMessage id="signup.next_button" />
              </IonButton>
            </div>
          </form>
        </Container>
      </IonContent>
    </IonPage>
  );
};
