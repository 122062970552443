import './timetable.scss';
import React, { useState, useEffect } from 'react';
import { AuthPage } from '../../../../components';
import { Typography } from '@mui/material';
import { style } from './../../../../Utility';
// import { SideMenu } from "App/ITCTeacher/Components";
import { SideMenu } from 'App/ITCStudent/Components';
import { getStudentTimetable } from 'api/api';

export const TimeTable = () => {

  const [studenttime, setstudenttime] = useState([])

  useEffect(() => {
    getStudentTimetable().then(res => {
      setstudenttime(res.data.data);
    })
  }, [])
  console.log(studenttime, "my student time table for student")


  return (
    <>
      <SideMenu />

      <AuthPage containerWidth='lg' title='Time Table' showBackButton>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='ion-padding' style={{ overflow: "auto" }}>
            <p className='color-tertiary heading'>MONDAY (28/03/2022)</p>
            <div className='simpleTable timetable'>
              <img src={studenttime[0]?.file_name} style={{ "height": "600px", "width": "100%" }} />

            </div>
          </div>
        </div>
      </AuthPage>
    </>
  );
};
