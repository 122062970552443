import "./index.scss";
import React, { useEffect, useState } from "react";
import { AuthPage, StudentDashboardCard } from "../../../../components";
import { Container, Stack, Typography } from "@mui/material";
import { createOutline } from "ionicons/icons";
import { style } from "./../../../../Utility";
import { GET } from "../../../../api/request";
import CircularProgress from '@mui/material/CircularProgress';
import { getCompletedClass } from "api/api";
// import { SideMenu } from "App/ITCTeacher/Components";

interface ProfileProps {}

export const Profile = (props: ProfileProps) => {
  const [teacherProfile, setTeacherProfile]: any = useState({});
  const [loader, setLoader] = useState(true);
  const [completedClass, setCompletedClass] = useState([]);
  useEffect(() => {
    GET("teacher/profile").then((response) => {
      const {data, status} = response
      if (status) {
        setTeacherProfile(data.data.data);
      }

      setLoader(false)

    });
    getCompletedClass().then((response) => {
      setCompletedClass(response.data.data);
    })
  }, []);
  if(loader)
  return <CircularProgress />
  return (
    <>
    {/* <SideMenu/> */}
    <AuthPage title="Profile">
      <div className="ion-padding">
        <StudentDashboardCard
          name={
            <p className="color-tertiary heading">
              {teacherProfile.name}
            </p>
          }
          showCamera
          title={
            <>
              <p className="profile">
                {teacherProfile.role}
              </p>
              {/* <p className="profile">SMK RAJAMAHADI</p> */}
              <p className="profile">{teacherProfile.institute}</p>
              {/* <p className="profile">KLANg SLANGER</p> */}
            </>
          }
          icon={createOutline}
          isTeacher
          image={teacherProfile.image}
        />
        <div className="timeline">
          <Typography>
            <b>Active Timeline</b>
          </Typography>
          <Typography>
            <span className="color-tertiary">{teacherProfile.joined_date} - {teacherProfile.last_login}</span>
          </Typography>
        </div>
        <div className="tableHeader">
          <Typography>Lessons Taught</Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="simpleTable">
            <table className="">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Lesson</th>
                  <th>Standard / Form</th>
                </tr>
              </thead>
              <tbody>
                {completedClass.length && completedClass.map((singleClass, key)=>(
                  <tr>
                    <td>{key+1}</td>
                    <td>{singleClass.subject_name}</td>
                    <td>{singleClass.standard_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AuthPage>
    </>
  );
};
