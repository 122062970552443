import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './class.scss';
import { AuthPage, ClassDetails } from 'components';
import { GET } from 'api/request';
// import { SideMenu } from "App/ITCTeacher/Components";
import CircularProgress from '@mui/material/CircularProgress';
import * as dayjs from "dayjs"
import * as weekday from "dayjs/plugin/weekday"
dayjs.extend(weekday)

export const StartClass = () => {
  let [loader, setLoader] = useState(true);
  let [classDetail, setClassDetail] : any = useState([])
  var week_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(()=> {
    GET("teacher/classes/current")
    .then((response:any)=> {
      const {data, status} = response
      if (status) {
        setClassDetail(data.data.data);
      }
      setLoader(false)
    })
    .catch((error:any) => {
      setLoader(false);
      setClassDetail([]);
      console.log(error)
    })
  },[])

  console.log("start class " , classDetail)

  if(loader)

  return <CircularProgress />

  return (
    <>
    
    {/* <SideMenu/> */}
    
    <AuthPage title='Join Classes' showBackButton containerWidth='sm'>      
        <div className='io-padding' style={{textAlign:'center'}}>
          <h4 className='color-tertiary classHeading'> {week_days[dayjs().weekday()]}{dayjs().format(" (DD/MM/YYYY)")}</h4>
          {classDetail.subject_name ? 
            <div className='classCard'>
              <ClassDetails subject={classDetail.subject_name} timing={`${classDetail.actual_start_time} - ${classDetail.actual_end_time}`} classLink={classDetail.link} />
            </div>
          : "No current class" }
        </div>
    </AuthPage>

    </> 
  );
};
