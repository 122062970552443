import './index.scss';
import React from 'react';
import { IonIcon, IonLabel, IonTitle } from '@ionic/react';

interface HeaderProps {
    title?: string;
    icon?: any;
    rightAlignIcon?: React.ReactNode;
    rightAlignIconOnClick?: () => any;
    onIconClick?: () => any;
    children?: React.ReactNode;
};

export const Header = (props: HeaderProps) => {
    return (
        <>
            <IonTitle className={"headerTitle"}>
                <IonLabel style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{props.icon && <IonIcon onClick={props.onIconClick} icon={props.icon} className="color-primary" style={{ fontSize: 'xx-large' }} />} {props.title}</div>
                    <div onClick={props.rightAlignIconOnClick}>{props.rightAlignIcon}</div>
                </IonLabel>
                {props.children}
            </IonTitle>
        </>
    );
};