import './index.scss';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { IonBadge } from '@ionic/react';

interface StatusCardProps {
  status?: string;
  description?: string | React.ReactNode;
  active?: boolean;
  color?: 'success' | 'warning' | 'danger';
  title?: string;
  style?: React.CSSProperties;
}
export const StatusCard = (props: StatusCardProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className='card' style={{ ...props.style }}>
        <Box className={`ion-padding status-root-${props.color}`}>
          <Box>
            <Typography
              sx={{ fontSize: 'large' }}
              className={`status-heading background-${props.color}`}
            >
              {props.title ?? 'STATUS'}
            </Typography>
            <div className='ion-padding'>
              {props.status ? (
                <Typography
                  sx={{
                    fontSize: 'x-large',
                  }}
                  className='status-value'
                >
                  {props.status.toUpperCase()}
                </Typography>
              ) : undefined}
              {props.active ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <IonBadge className='status-badge' color='success'>
                    <span>Fully Paid</span>
                  </IonBadge>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: 'small',
                    color: '#494949',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {props.description}
                </div>
              )}
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};
