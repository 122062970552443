import { GET, POST, PATCH } from "./request";
import { toast } from "react-toastify";
import axios from 'axios';

export const contactUs = async (data) => {
  try {
    const response = await GET("teacher/contact-us");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Invalid username and password");
    }
  } catch (error) {
    // toast.error("Invalid username and password");
  }
};

export const submitPayment = async (data) => {
  try {
    const response = await POST("student/payments/generate/new-payment", { data });
    if (response.data.status) {
      return response.data;
    }
  } catch (error) {

  }
}

export const submitSlip = async (data) => {
  console.log("dataaaaaaaaaa", data)
  try {
    const response = await POST("student/payments/purchase-package/upload-slip", { data });
    if (response.data.status) {
      toast.success("Payment success")
      return response.data;
    } else {
      toast.success("Payment success")

    }
  } catch (error) {
    toast.error("Payment unsuccess");
  }
}

export const teacherTimetable = async () => {
  try {
    const response = await GET("teacher/timetable");

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
};

export const getOtpOnEmail = async (data) => {
  try {
    const response = await POST("teacher/send-forgot-pswd-otp", { data });

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Invalid email");
    }
  } catch (error) {
    toast.error("Invalid email");
  }
}


export const checkOtp = async (data) => {
  try {
    const response = await POST("teacher/verify-otp", { data });

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Invalid otp");
      return false
    }
  } catch (error) {
    toast.error("Invalid otp");
    return false
  }
}


export const setNewPassword = async (data) => {
  try {
    const response = await PATCH("student/forgot-password", { data });

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Invalid request");
      return false
    }
  } catch (error) {
    toast.error("Invalid request");
    return false
  }
}


export const getUpcomingProgram = async () => {
  try {
    const response = await GET("teacher/upcoming-programs");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Invalid username and password");
    }
  } catch (error) {
    // toast.error("Invalid username and password");
  }

}

export const getCompletedClass = async () => {
  try {
    const response = await GET("teacher/classes/completed");

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error("Something went wrong");
  }

}


export const getStudentProgress = async () => {
  try {
    const response = await GET("student/progress-report");

    if (response.data.status) {
      return response.data.data;
    } else {
      //   toast.error("Invalid username and password");
    }
  } catch (error) {
    // toast.error("Invalid username and password");
  }

}

export const getUpcomingProgramForStudents = async () => {
  try {
    const response = await GET("student/upcoming-programs");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Upcoming programs data not found");
    }
  } catch (error) {
    // toast.error("Upcoming programs data not found");
  }

}


export const getTeacherProfile = async () => {
  try {
    const response = await GET("teacher/profile");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Upcoming programs data not found");
    }
  } catch (error) {
    // toast.error("Upcoming programs data not found");
  }

}


export const getAnnouncements = async () => {
  try {
    const response = await GET("student/announcements");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Could not fetch announcements data");
    }
  } catch (error) {
    // toast.error("Could not fetch announcements data");
  }

}

export const getAnnouncementsTeacher = async () => {
  try {
    const response = await GET("teacher/announcements");

    if (response.data.status) {
      return response.data;
    } else {
      //   toast.error("Could not fetch announcements data");
    }
  } catch (error) {
    // toast.error("Could not fetch announcements data");
  }

}

export const getStatesList = async () => {
  try {
    const response = await GET("teacher/states");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getCityList = async () => {
  try {
    const response = await GET("teacher/cities/1");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getTeacherAtt = async () => {
  try {
    const response = await GET("teacher/attendance");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getStudentTimetable = async () => {
  try {
    const response = await GET("student/timetable");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getTeachernotes = async () => {
  try {
    const response = await GET("teacher/notes");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}


export const getStudentAtt = async () => {
  try {
    const response = await GET("student/attendance");
    if (response.status) {
      return response.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getClassList = async () => {
  try {
    const response = await GET("student/standards");
    if (response.status) {
      return response.data.data;
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}


export const getSubjectList = async (id) => {
  try {
    const response = await GET("student/notes");
    if (response.status) {
      return response.data;
      // return ['1', '2', '3']
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

export const getEnrollSubject = async (id, id1) => {
  try {

    console.log(`testing + student/packages/${id}/category/${id1}/subjects`);
    const response = await GET(`student/packages/${id}/category/${id1}/subjects`);

    if (response.status) {
      return response.data;
      // return ['1', '2', '3']
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}



export const getPdfList = async (id) => {
  try {
    const response = await GET(`student/notes/${id}/download`);
    if (response.status) {
      return response.data;
      // return ['1', '2', '3']
    }
    else {
      //   toast.error("could not fetch state list data");
    }
  }
  catch (error) {
    //   toast.error("could not fetch state list data");
  }
}

// export const feedbackSubmit = async (data) => {
//   try {
//       const response = await POST("teacher/feedback/store", {data});

//       if (response.data.status) {
//         return response.data;
//       } else {
//         toast.error("Feedback not submited");
//       }
//     } catch (error) {
//       toast.error("Feedback not submited");
//     }
// }

export const feedbackTeacher = async (data) => {
  try {
    const response = await POST("teacher/feedback/store", { data });

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}


export const feedbackStudent = async (data) => {
  try {
    const response = await POST("student/feedback/store", { data });

    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const profilePhoto = async (data) => {
  let profile = localStorage.getItem("loggedUserType") ? localStorage.getItem("loggedUserType") : "";
  console.log(profile, "dataaaaaaaaaaaaaeeeeeeeeeeeee");
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  var bodyFormData = new FormData();
  bodyFormData.append('image', data);
  try {
    let url = profile == 'Student' ? "student/profile/update-image" : "teacher/profile/update-image"
    // const response = await POST("teacher/profile/update-image", {...data, headers: { "Content-Type": "multipart/form-data" }}, {'Content-Type':"multipart/form-data"});
    axios({
      method: "post",
      url: baseUrl + url,
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('itc_token')}`,
        "Content-Type": "multipart/form-data"
      },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // if (response.data.status) {
    //   return response.data;
    // } else {
    //   toast.error("Feedback not submited");
    // }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const getPackage = async () => {
  try {
    const response = await GET("student/packages");
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const getPackageCategory = async (id) => {
  try {
    const response = await GET(`student/packages/${id}/category`);
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const getRecording = async () => {
  try {
    const response = await GET("student/recordings");
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const getNotification = async () => {
  try {
    const response = await GET("student/notifications");
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}

export const getReferrel = async () => {
  try {
    const response = await GET("student/referred-users");
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}


export const getInviteData = async () => {
  try {
    const response = await GET("student/invite-friend");
    if (response.data.status) {
      return response.data;
    } else {
      toast.error("Feedback not submited");
    }
  } catch (error) {
    toast.error("Feedback not submited");
  }
}