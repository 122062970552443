import classes from './index.module.scss';
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Header } from '../../components';
import { useHistory } from 'react-router';
import { arrowUndoSharp } from 'ionicons/icons';
import { setNewPassword } from 'api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from "react-router-dom";


export const NewPassword = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const password_regex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  const [loader, setLoader] = useState(false);
  useEffect(()=> {
    if(typeof location.state == 'undefined') {
      history.push('/login')
    }
  },[])
  const [data, setData] = useState({
    "otp": location.state.data.otp,
    "email": location.state?.data.email,
    "password": "",
    "confirm_password": ""
  });
  const [error, setError] = useState({
    "password": "",
    "confirm_password": ""
  });

  const [otpError, setOtpError] = useState("");
  const submitForm = () => {
    setLoader(true);
    if (!password_regex.test(data.password)) {
        setError({...error, "password": "Enter valid password"});
    } else if (data.confirm_password != data.password) {
        setError({...error, "confirm_password": "Password and confirm password doesn't match"});
    } else {
      setError({
        "password": "",
        "confirm_password": ""
      });
      setNewPassword(data)
        .then((response) => {
          if(response){
            console.log(response, "ressssssssssssss");
            history.push('/login')
          }
        })
    }
    setLoader(false);
  }
  console.log(data, "dataaaaaaaaaaaaaaa")
  if (loader)
    return <CircularProgress />
  return (
    <IonPage>
      <IonContent className={classes['login-form']} fullscreen>
        <Header icon={arrowUndoSharp} onIconClick={() => history.goBack()} />
        <form className={`ion-padding `}>
          <div
            className='ion-padding'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div style={{ maxWidth: '70%' }}>
              <Stack spacing={3} direction='column' justifyContent='center'>
                <IonToolbar>
                  <Typography
                    variant='h5'
                    style={{ fontWeight: 'bolder', fontSize: 'xx-large' }}
                  >
                    <FormattedMessage id='forgot_password.enter_password' />
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 'bolder', color: 'gray' }}
                  >
                    <FormattedMessage id='forgot_password.enter_password_subtitle' />
                  </Typography>
                </IonToolbar>
                <div>
                  <TextField
                    label='Password'
                    type='password'
                    id='outlined-size-small'
                    size='medium'
                    fullWidth
                    color='primary'
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                  />
                  <p style={{ color: "red" }}>{error.password}</p>
                </div>
                <div>
                  <TextField
                    label='Confirm Password'
                    type='password'
                    id='outlined-size-small'
                    size='medium'
                    fullWidth
                    color='primary'
                    onChange={(e) => setData({ ...data, confirm_password: e.target.value })}
                  />
                  <p style={{ color: "red" }}>{error.confirm_password}</p>
                </div>
                <p style={{color:"rgb(100, 100, 100)"}}>Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character</p>
                <div>
                  <IonButton
                    className='ion-margin-top'
                    expand='full'
                    shape='round'
                    size='large'
                    onClick={() => submitForm()}
                  >
                    <FormattedMessage id='forgot_password.check_otp' />
                  </IonButton>
                </div>
              </Stack>
            </div>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};
