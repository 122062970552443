import React, { useState } from "react";
import "./login.scss";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonPage,
  getPlatforms,
  IonText,
  IonIcon,
  IonButtons
} from "@ionic/react";
import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { arrowBackOutline, search } from "ionicons/icons";
import { requestForToken } from "../../firebase";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { setLoggedIn, style, setType, protectedRoute } from "./../../Utility";
import watermark from "./../../assets/watermark.svg";
import logoSvg from "./../../assets/ITCLogo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { setLoggedUserType } from "../../redux/constants/common";
import { login, studentLogin } from "../../api/auth";
import { TextInput } from "../../App/ITCTeacher/Components/inputFields";
import { useFormik } from "formik";
import * as yup from "yup";
import SelectType from "./SelectType";
interface LoginProps { }

export const Login = (props: LoginProps) => {
  const history = useHistory();
  let [data, setData] = useState<any>({
    email: null,
    password: null,
  });

  let [error, setError] = useState<any>({
    email: null,
    password: null,
    message: null,
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // const handleName = (feild: string, value: string) => {
  //   // console.log('setingggggggg')
  //   setData({ ...data, [feild]: value });
  // };
  // console.log("dataaaaaaaaa", data);
  const [loginType, setLoginType] = useState("");

  const { loggedUserType } = useSelector(
    (state: RootStateOrAny) => state.common
  );

  React.useEffect(() => {
    dispatch(setLoggedUserType(""));
    protectedRoute(false)
    requestForToken();
  }, []);

  const dispatch = useDispatch();
  const onLogin = async (login_data: any) => {
    const isemail = validateEmail(login_data.email)
    const loginwith = isemail ? 'email':'mobile'
    
    if (loginType == "teacher") {
      await login(login_data,loginwith)
        .then((res) => {
          if (res.status) {
            console.log(res.data)
            setType("teacher");
            dispatch(setLoggedUserType("teacher"));
            history.push("./tabs/home");
            setLoggedIn(true);
          } else {
            setError({
              ...error,
              email: res?.errors?.email?.[0],
              password: res?.errors?.password?.[0],
              message: res?.message ? res.message : "",
            });
          }
        })
        .catch((er) => {
          console.log("er.data :>> ", er);
        });
    } else {
      await studentLogin(login_data,loginwith)
        .then((res) => {
          console.log('student login responce is ',res)
          if (res.status) {
            console.log(res.data)
            setType("student");
            dispatch(setLoggedUserType("student"));
            history.push("./student");
            setLoggedIn(true);
          } else {
            // console.log("res.errors :>> ", res.errors);

            setError({
              ...error,
              email: res?.errors?.email?.[0],
              password: res?.errors?.password?.[0],
              message: res?.message ? res.message : "",
            });
          }
        })
        .catch((er) => {
          console.log("er.data :>> ", er);
        });
    }
  };
  const onSignup = () => {
    history.push({
      pathname: "/signup",
      state: { data: loginType },
    });
  };

  const loginSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      device_id: "123456",
      device_type: "android",
    },
    validationSchema: loginSchema,
    onSubmit: (values: any) => {
      console.log("values :>> ", values);
      onLogin(values);
    },
  });
  console.log({ platforms: getPlatforms() });


  return (
    <IonPage>
      <IonContent
        className={`watermark login ion-padding ${style["login-form"]}`}
        fullscreen
      >
        <img src={watermark} className="hideOnWeb" />
        <Container maxWidth="lg" className="container">
        

          

          {
            (loginType === '') ? <SelectType setLoginType={setLoginType} /> :
            <>
            <IonButtons style={{position: 'absolute'}} slot="start">
            <IonIcon
              style={{
                verticalAlign: "middle",
                fontSize: "2rem",
                //   float: 'left',
              }}
              icon={arrowBackOutline}
              onClick={() => setLoginType("")}
            />
          </IonButtons>
              <form className="form-wrapper" onSubmit={formik.handleSubmit}>
                <div className="flex">
                  <div className="logo">
                    <img src={logoSvg} alt="" />
                  </div>

                  <div className="ion-text-center">
                    <IonText color="primary">
                      <h2>
                        <b>
                          {loginType == "teacher" ? "Teacher" : "Student"} Login
                        </b>
                      </h2>
                    </IonText>
                  </div>
                  <Stack spacing={3}>
                    <TextInput
                      formik={formik}
                      label={"E-mail or Mobile Number"}
                      field_name="email"
                    />

                    {/* <TextField
                  sx={{ textAlign: "center" }}
                  label="E-mail or Mobile Number"
                  id="outlined-size-small"
                  size="medium"
                  fullWidth
                  value={data.email}
                  onChange={(e) => {
                    handleName('email',e.target.value)
                  }}
                  color="primary"
                />
                <span style={{color:'red'}}>{error.email}</span> */}

                    <TextInput
                      formik={formik}
                      label={"Password"}
                      field_name="password"
                      type="password"
                    />
                    {/* <TextField
                  sx={{ textAlign: style.inputTextAlign }}
                  label="Password"
                  type="password"
                  id="outlined-size-small"
                  size="medium"
                  fullWidth
                  value={data.password}
                  onChange={(e) => handleName('password', e.target.value)}
                  color="primary"
                />
                <span style={{color:'red'}}>{error.password}</span> */}
                    <span style={{ color: "red" }}>{error.message}</span>
                  </Stack>
                  <div className="helperBtn">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        padding: "0",
                        paddingTop: ".5rem",
                      }}
                    >
                      <IonCheckbox className="ioncheckbox" />{" "}
                      <Link
                        to="/forgot-password"
                        style={{
                          color: "gray",
                          textDecoration: "none",
                          fontSize: "16px",
                        }}
                      >
                        <FormattedMessage id="login.remember_me" />
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        padding: "0",
                        paddingTop: ".5rem",
                      }}
                    >
                      <Link
                        to="/forgot-password"
                        style={{ textDecoration: "none" }}
                      >
                        <FormattedMessage id="login.forgot_password" />
                      </Link>
                    </Typography>
                  </div>
                </div>
                <div className="loginBtnWrapper">
                  {/* <div className="ion-text-center ion-padding-vertical">
                <IonText
                  color="secondary"
                  onClick={() =>
                    setLoginType((prev) =>
                      prev == "student" ? "teacher" : "student"
                    )
                  }
                >
                  <h6>
                    <b>
                      Click here to login as
                      {loginType != "teacher" ? " Teacher" : " Student"}
                    </b>
                  </h6>
                </IonText>
              </div> */}
                  <Stack spacing={0}>
                    <IonButton
                      color="primary"
                      expand="full"
                      shape="round"
                      size="large"
                      // onClick={onLogin}
                      type="submit"
                      className="button authBtn"
                    >
                      <FormattedMessage id="login.button_text" />
                    </IonButton>
                    <IonButton
                      color="secondary"
                      expand="full"
                      shape="round"
                      size="large"
                      className="button authBtn"
                      onClick={onSignup}
                    >
                      <FormattedMessage id="login.signup" />
                    </IonButton>
                  </Stack>
                </div>

              </form>
              </>
          }



        </Container>
      </IonContent>
    </IonPage>
  );
};